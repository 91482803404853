import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@/components/Elements";
import { useNotificationStore } from "@/stores/notifications";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const PRESET_COLORS = [
  "#EF4444", // Red
  "#F97316", // Orange
  "#F59E0B", // Amber
  "#84CC16", // Lime
  "#10B981", // Emerald
  "#06B6D4", // Cyan
  "#3B82F6", // Blue
  "#6366F1", // Indigo
  "#8B5CF6", // Violet
  "#EC4899", // Pink
];

const createTagSchema = z.object({
  tagName: z.string().min(1, "Tag name is required").max(20),
  color: z.string(),
});

type CreateTagFormValues = z.infer<typeof createTagSchema>;

interface CreateHubTagDialogProps {
  open: boolean;
  onCancel: () => void;
  onFinalize: (tagName: string, color: string) => Promise<void>;
}

export const CreateHubTagDialog = ({
  open,
  onCancel,
  onFinalize,
}: CreateHubTagDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotificationStore();

  const form = useForm<CreateTagFormValues>({
    resolver: zodResolver(createTagSchema),
    defaultValues: {
      tagName: "",
      color: PRESET_COLORS[0],
    },
  });

  const handleCreateTag = async (values: CreateTagFormValues) => {
    try {
      setIsLoading(true);
      await onFinalize(values.tagName.trim(), values.color);

      handleClose();
    } catch (error) {
      console.error("Failed to create tag:", error);
      addNotification({
        type: "error",
        title: "Failed to create tag",
        message: "An error occurred while creating the tag.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    form.reset();
    onCancel();
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New Tag</DialogTitle>
        </DialogHeader>

        <DialogDescription className="space-y-4 px-4">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleCreateTag)}
              className="space-y-4"
            >
              <FormField
                control={form.control}
                name="tagName"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Tag Name</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Enter tag name"
                        {...field}
                        className="w-full"
                      />
                    </FormControl>
                    {fieldState.error && (
                      <FormMessage>{fieldState.error.message}</FormMessage>
                    )}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="color"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tag Color</FormLabel>
                    <div className="flex flex-wrap gap-2">
                      {PRESET_COLORS.map((color) => (
                        <button
                          type="button"
                          key={color}
                          onClick={() => field.onChange(color)}
                          className={`w-8 h-8 rounded-full border-2 transition-all ${
                            field.value === color
                              ? "border-zinc-900 dark:border-white scale-110"
                              : "border-transparent hover:scale-110"
                          }`}
                          style={{ backgroundColor: color }}
                        />
                      ))}
                    </div>
                  </FormItem>
                )}
              />
              <div className="mt-4">
                <label className="block text-sm font-medium text-zinc-700 dark:text-zinc-300 mb-2">
                  Preview
                </label>
                <div className="flex items-center gap-2">
                  <span
                    className="inline-flex items-center gap-1 rounded-full px-2 py-1 text-xs"
                    style={{
                      backgroundColor: `${form.watch("color")}1A`, // 10% opacity
                      color: form.watch("color"),
                    }}
                  >
                    {form.watch("tagName") || "Tag Preview"}
                  </span>
                </div>
              </div>
            </form>
          </Form>
        </DialogDescription>
        <DialogFooter className="px-4 pb-4">
          <div className="flex justify-end space-x-2">
            <Button variant="outlineBlur" onClick={handleClose} type="button">
              Cancel
            </Button>
            <Button
              onClick={form.handleSubmit(handleCreateTag)}
              variant="primaryBlur"
              disabled={!form.formState.isValid}
              isLoading={isLoading}
            >
              Create Tag
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateHubTagDialog;
