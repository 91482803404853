export const formalityLevels = [
  {
    value: 1,
    short: "Very Informal",
    long: "Casual, conversational, slang-friendly",
  },
  { value: 2, short: "Informal", long: "Relaxed but clear" },
  { value: 3, short: "Neutral", long: "Balanced, professional" },
  { value: 4, short: "Formal", long: "Professional and structured" },
  { value: 5, short: "Very Formal", long: "Highly professional, academic" },
];

export const politenessLevels = [
  { value: 1, short: "Very Direct", long: "Straight to the point" },
  { value: 2, short: "Direct", long: "Clear but considerate" },
  { value: 3, short: "Balanced", long: "Professional courtesy" },
  { value: 4, short: "Polite", long: "Extra courteous" },
  { value: 5, short: "Very Polite", long: "Extremely deferential" },
];

export const emotionLevels = [
  { value: 1, short: "Very Neutral", long: "Factual, unemotional" },
  { value: 2, short: "Slightly Emotional", long: "Hints of feeling" },
  { value: 3, short: "Moderately Emotional", long: "Clear feeling" },
  { value: 4, short: "Emotional", long: "Strong feeling" },
  { value: 5, short: "Very Emotional", long: "Highly expressive" },
];

export const assertivenessLevels = [
  { value: 1, short: "Very Gentle", long: "Suggestive, optional" },
  { value: 2, short: "Gentle", long: "Soft recommendations" },
  { value: 3, short: "Balanced", long: "Clear stance" },
  { value: 4, short: "Assertive", long: "Strong stance" },
  { value: 5, short: "Very Assertive", long: "Commanding, decisive" },
];

export const targetAudiences = [
  "General Audience",
  "Professionals",
  "Executives",
  "Marketers",
  "Search Audience",
  "Customers",
  "Small Businesses",
  "Academics",
];
