import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
} from "@/components/Elements";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import { useNotificationStore } from "@/stores/notifications";
import { zodResolver } from "@hookform/resolvers/zod";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { format } from "date-fns";
import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { TbArrowLeft, TbCheck } from "react-icons/tb";
import { useQueryClient } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { z } from "zod";
import { useGetHubDocument } from "../api/getHubDocument";
import { useUpdateHubDocument } from "../api/updateHubDocument";
import { EditState, HubDocument } from "../types";

const theme = {
  paragraph: "PlaygroundEditorTheme__paragraph",
  text: {
    base: "PlaygroundEditorTheme__text",
    bold: "PlaygroundEditorTheme__textBold",
    italic: "PlaygroundEditorTheme__textItalic",
    underline: "PlaygroundEditorTheme__textUnderline",
    strikethrough: "PlaygroundEditorTheme__textStrikethrough",
    underlineStrikethrough: "PlaygroundEditorTheme__textUnderlineStrikethrough",
  },
};

const documentFormSchema = z.object({
  title: z.string().min(1, "Title is required"),
  content: z.string(),
});

type DocumentFormValues = z.infer<typeof documentFormSchema>;

interface HubDocumentEditorContentProps {
  document: HubDocument;
}

type SetEditState = React.Dispatch<React.SetStateAction<EditState>>;

export function AutoSavePlugin({
  document,
  setEditState,
  form,
  lastSavedContentRef,
  debouncedSaveRef,
}: {
  document: HubDocument;
  setEditState: SetEditState;
  form: UseFormReturn<DocumentFormValues>;
  lastSavedContentRef: React.RefObject<{ content: string; name: string }>;
  debouncedSaveRef: React.RefObject<ReturnType<typeof debounce>>;
}) {
  const [editor] = useLexicalComposerContext();
  const updateHubDocumentMutation = useUpdateHubDocument();
  const queryClient = useQueryClient();

  const saveDocument = async (content: string, name: string) => {
    try {
      if (
        (content === lastSavedContentRef.current.content &&
          name === lastSavedContentRef.current.name) ||
        !name.trim()
      )
        return;

      setEditState({ isDirty: false, isSaving: true, lastSaved: null });

      await updateHubDocumentMutation.mutateAsync({
        id: document.id,
        updates: { content, name },
      });

      lastSavedContentRef.current = { content, name };
      await Promise.all([
        queryClient.invalidateQueries(["hubDocuments"]),
        queryClient.invalidateQueries(["hubDocument", document.hash]),
      ]);

      setEditState({
        isDirty: false,
        isSaving: false,
        lastSaved: new Date(),
      });
    } catch (error) {
      setEditState({ isDirty: true, isSaving: false, lastSaved: null });
      console.error("AutoSave failed:", error);
    }
  };

  // Store the debounced function in the ref
  debouncedSaveRef.current = useCallback(debounce(saveDocument, 1500), []);
  const debouncedSave = debouncedSaveRef.current;

  // Cleanup
  useEffect(() => {
    return () => {
      debouncedSave?.cancel();
    };
  }, [debouncedSave]);

  // Handle content changes
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const content = $getRoot().getTextContent();
        const currentTitle = form.getValues("title");

        if (content !== lastSavedContentRef.current.content) {
          setEditState((current) => ({
            ...current,
            isDirty: true,
          }));
          debouncedSave?.(content, currentTitle);
        }
      });
    });
  }, [editor, debouncedSave, form]);

  // Handle title changes
  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name === "title" && value.title) {
        const content = editor
          .getEditorState()
          .read(() => $getRoot().getTextContent());
        setEditState((current) => ({
          ...current,
          isDirty: true,
        }));
        debouncedSave?.(content, value.title);
      }
    });

    return () => subscription.unsubscribe();
  }, [form, editor, debouncedSave]);

  return null;
}

const Breadcrumb = () => {
  return (
    <div className="flex items-center px-6 py-2 bg-white border-b dark:bg-zinc-900 dark:border-zinc-800">
      <Link
        to="/app/knowledge-hub"
        className="flex items-center text-sm text-zinc-600 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white transition-colors"
      >
        <TbArrowLeft className="w-4 h-4 mr-1" />
        Back to Knowledge Hub
      </Link>
    </div>
  );
};

const HubDocumentEditorContent = ({
  document: hubDocument,
}: HubDocumentEditorContentProps) => {
  const [editor] = useLexicalComposerContext();
  const [isEditorEmpty, setIsEditorEmpty] = useState(true);
  const [editState, setEditState] = useState<EditState>({
    isDirty: false,
    isSaving: false,
    lastSaved: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const { addNotification } = useNotificationStore();
  const updateHubDocumentMutation = useUpdateHubDocument();
  const queryClient = useQueryClient();

  const lastSavedContentRef = useRef({
    content: hubDocument.content,
    name: hubDocument.name,
  });
  const debouncedSaveRef = useRef<ReturnType<typeof debounce>>();

  const form = useForm<DocumentFormValues>({
    resolver: zodResolver(documentFormSchema),
    defaultValues: {
      title: hubDocument.name || "",
      content: hubDocument.content || "",
    },
  });

  const onSubmit = async (data: DocumentFormValues) => {
    try {
      setIsEditing(true);
      debouncedSaveRef.current?.cancel();

      // Store the current editor state
      const currentEditorState = editor.getEditorState();
      const content = currentEditorState.read(() =>
        $getRoot().getTextContent()
      );

      await updateHubDocumentMutation.mutateAsync({
        id: hubDocument.id,
        updates: { content, name: data.title },
      });

      await Promise.all([
        queryClient.invalidateQueries(["hubDocuments"]),
        queryClient.invalidateQueries(["hubDocument", hubDocument.hash]),
      ]);

      // Maintain the same editor state
      editor.setEditorState(currentEditorState);

      lastSavedContentRef.current = { content, name: data.title };

      setEditState({
        isDirty: false,
        isSaving: false,
        lastSaved: new Date(),
      });

      addNotification({
        type: "success",
        title: "Document saved successfully",
      });
    } catch (error) {
      addNotification({
        type: "error",
        title: "Failed to save document",
        message: "Please try again",
      });
    } finally {
      setIsEditing(false);
    }
  };

  // Initialize editor content only once per document
  useEffect(() => {
    if (!hubDocument.content) return;

    editor.update(() => {
      const root = $getRoot();
      if (root.getTextContent() === "") {
        // Only initialize if empty
        root.clear();
        const paragraph = $createParagraphNode();
        paragraph.append($createTextNode(hubDocument.content));
        root.append(paragraph);
      }
    });
    const editorElement = document.querySelector("#editor");
    setTimeout(() => {
      if (editorElement) {
        editorElement.scrollTop = 0;
      }
    }, 0);
  }, [editor, hubDocument.id]);

  // Track empty state
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const content = $getRoot().getTextContent();
        setIsEditorEmpty(content.trim().length === 0);
      });
    });
  }, [editor]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col h-full"
      >
        <div className="flex items-center justify-between px-6 h-14 border-b dark:border-zinc-800">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <textarea
                    {...field}
                    placeholder="Document title"
                    title={field.value}
                    className="h-[42px] py-0.5 w-full leading-[1.4] outline-none border-none 
                      whitespace-pre-wrap break-words ring-transparent focus:outline-none 
                      focus:border-none focus:ring-transparent text-[2em] font-bold 
                      placeholder:text-zinc-400 dark:placeholder:text-zinc-600 
                      bg-transparent text-zinc-900 dark:text-zinc-50 resize-none overflow-hidden"
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <div className="flex items-center gap-4">
            {editState.isSaving ? (
              <span className="text-sm text-zinc-500 whitespace-nowrap">
                Saving...
              </span>
            ) : editState.lastSaved ? (
              <span className="text-sm text-zinc-500 whitespace-nowrap">
                Auto-saved {format(editState.lastSaved, "h:mm a")}
              </span>
            ) : null}
            <Button
              variant="primaryBlur"
              size="xs"
              type="submit"
              isLoading={isEditing}
              startIcon={<TbCheck className="w-4 h-4" />}
            >
              Save
            </Button>
          </div>
        </div>
        <div
          id="editor"
          className="flex flex-row overflow-y-auto overflow-x-hidden h-[calc(100vh-93px)] print:overflow-visible print:h-auto w-full"
        >
          <div className="relative mx-auto flex w-full max-w-[750px]">
            <div className="relative w-full" style={{ maxWidth: "100%" }}>
              <div className="mt-14"></div>
              <div className="pb-96 relative">
                {isEditorEmpty && (
                  <div className="absolute text-zinc-400 dark:text-zinc-600 pointer-events-none px-8 top-0 left-0">
                    Start writing...
                  </div>
                )}
                <FormField
                  control={form.control}
                  name="content"
                  render={() => (
                    <FormItem>
                      <FormControl>
                        <ContentEditable className="outline-none min-h-full px-8 overflow-y-auto" />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <AutoSavePlugin
        document={hubDocument}
        setEditState={setEditState}
        form={form}
        lastSavedContentRef={lastSavedContentRef}
        debouncedSaveRef={debouncedSaveRef}
      />
    </Form>
  );
};

const HubDocumentEditor = () => {
  const location = useLocation();
  const hash = location.pathname.split("/").pop();
  const { data: document, isLoading } = useGetHubDocument({
    hash: hash as string,
  });

  const initialConfig = {
    namespace: "HubDocumentEditor",
    theme,
    onError: (error: Error) => {
      console.error(error);
    },
  };

  if (isLoading) {
    return (
      <div className="relative flex items-center justify-center w-full h-full">
        <LogoSpinner variant="md" loadingText="Loading document..." />
      </div>
    );
  }

  if (!document) {
    return (
      <div className="relative flex flex-col items-center justify-center w-full h-full">
        <div className="text-zinc-600 dark:text-zinc-300 text-lg font-medium">
          Document not found
        </div>
        <p className="mt-2 text-sm text-zinc-500 dark:text-zinc-400">
          The document you're looking for doesn't exist or has been deleted
        </p>
      </div>
    );
  }

  return (
    <div className="h-full bg-white dark:bg-zinc-900">
      <Breadcrumb />
      <LexicalComposer initialConfig={initialConfig}>
        <PlainTextPlugin
          contentEditable={<HubDocumentEditorContent document={document} />}
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}
        />
      </LexicalComposer>
    </div>
  );
};

export default HubDocumentEditor;
