import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";

export const deleteBrandVoice = async ({
  id,
}: {
  id: number;
}): Promise<string> => {
  return axios.post("/deleteBrandVoice", { id });
};

type UseDeleteBrandVoiceOptions = {
  config?: MutationConfig<typeof deleteBrandVoice>;
};

export const useDeleteBrandVoice = ({
  config,
}: UseDeleteBrandVoiceOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: deleteBrandVoice,
  });
};
