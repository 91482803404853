import { Combobox } from "@/components/Elements";
import { useAuth } from "@/lib/auth";
import { cn } from "@/utils/style";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { UpdateHubDocumentFields } from "../../api/updateHubDocument";
import { HubDocument } from "../../types";

type HubVisibilityCellProps = {
  document: HubDocument;
  onFieldUpdate: (
    updates: { field: keyof UpdateHubDocumentFields; value: any }[],
    data: HubDocument | HubDocument[]
  ) => void;
};

export const VisibilityTag = ({ children }: { children: string }) => {
  return (
    <span className="inline-flex items-center rounded-full px-2 py-1 text-2xs text-zinc-600 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-white">
      <span className="truncate capitalize">{children}</span>
    </span>
  );
};

export const HubVisibilityCell = ({
  document,
  onFieldUpdate,
}: HubVisibilityCellProps) => {
  const { user } = useAuth();
  const visibilityOptions = [
    {
      label: "Private",
      value: "private",
      icon: <EyeSlashIcon className="h-3 w-3 mr-2" />,
      onClick: (_value: any, data: HubDocument) => {
        onFieldUpdate([{ field: "visibility", value: "private" }], data);
      },
    },
    {
      label: "Team",
      value: "team",
      icon: <EyeIcon className="h-3 w-3 mr-2" />,
      onClick: (_value: any, data: HubDocument) => {
        onFieldUpdate([{ field: "visibility", value: "team" }], data);
      },
    },
  ];

  return (
    <Combobox
      data={document}
      options={visibilityOptions}
      buttonVariant="buttonNode"
      // @ts-ignore
      buttonClassName="rounded-full"
      value={document.visibility}
      showEndIcon={false}
      className={cn("h-7")}
      disabled={document.createdBy !== user?.id}
      trigger={<VisibilityTag>{document.visibility}</VisibilityTag>}
    />
  );
};
