import { ArticleMetadata } from "@/components/Elements";
import { isValidUrl } from "@/utils/isValidUrl";
import { cn } from "@/utils/style";
import { useMotionValue } from "framer-motion";
import React, { useCallback } from "react";
import ArticleAssets from "../Asset/ArticleAssets";
import { SerpMenu } from "./SerpMenu";

interface ArticleCardProps {
  title: string;
  description: string;
  assets: Array<SerpAssetProps>;
  url: string;
  rank: number;
  wordCount: number;
  links: number;
  domainAuthority: number;
  className?: string;
  onAssetClick?: (actionType: string) => void;
  selectionEnabled?: boolean;
  selectedAssets?: Record<string, any>;
  menuEnabled?: boolean;
  isCustomImport?: boolean;
  isProcessed?: boolean;
}

interface SerpAssetProps {
  header: string;
  html: Array<string>;
  header_tag: string;
  text: Array<string>;
}

const ArticleCardHeader = React.memo(({ title }) => (
  <h3 className="mt-1 text-sm font-medium text-zinc-900 dark:text-zinc-50">
    {title}
  </h3>
));

const ArticleCardDescription = React.memo(({ description }) => (
  <p className="mt-1 text-2xs text-zinc-600 dark:text-zinc-400">
    {description}
  </p>
));

export const ArticleCard = React.memo(
  ({
    title,
    description,
    assets,
    url,
    rank,
    wordCount,
    links,
    domainAuthority,
    className,
    onAssetClick,
    selectionEnabled,
    selectedAssets,
    menuEnabled = true,
    isCustomImport,
    isProcessed = true,
  }: ArticleCardProps) => {
    const mouseX = useMotionValue(0);
    const mouseY = useMotionValue(0);

    const onMouseMove = useCallback(
      ({ currentTarget, clientX, clientY }) => {
        const { left, top } = currentTarget.getBoundingClientRect();
        mouseX.set(clientX - left);
        mouseY.set(clientY - top);
      },
      [mouseX, mouseY]
    );

    const assetsWithUrl: SerpAssetProps[] = assets?.reduce(
      (unique: SerpAssetProps[], asset) => {
        const isDuplicate = unique.some((u) => u.header === asset.header);
        if (!isDuplicate) {
          unique.push({ ...asset, url: url });
        }
        return unique;
      },
      []
    );

    const urlHost = isValidUrl(url)
      ? new URL(url).hostname.replace("www.", "")
      : url;

    return (
      <li
        onMouseMove={onMouseMove}
        className={cn("relative flex w-full group", className)}
      >
        {menuEnabled && <SerpMenu url={url} assets={assetsWithUrl} />}
        <div className="relative flex flex-col py-2 pl-4 w-full">
          <ArticleMetadata
            isCustomImport={isCustomImport}
            url={url}
            urlHost={urlHost}
            rank={rank}
            wordCount={wordCount}
            links={links}
            domainAuthority={domainAuthority}
            isProcessed={isProcessed}
          />
          <ArticleCardHeader title={title} />
          <ArticleCardDescription description={description} />
          <ArticleAssets
            assets={assetsWithUrl}
            onAssetClick={onAssetClick}
            selectionEnabled={selectionEnabled}
            selectedAssets={selectedAssets}
          />
        </div>
      </li>
    );
  }
);
