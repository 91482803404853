import { getUser } from "@/features/auth";
import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { HubDocument } from "../types";

export const getHubDocuments = async (): Promise<HubDocument[]> => {
  const user = await getUser();
  return axios.post("/getHubDocuments", { orgId: user?.orgId, light: true });
};

type QueryFnType = typeof getHubDocuments;

type UseGetHubDocumentsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useGetHubDocuments = ({
  config,
}: UseGetHubDocumentsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["hubDocuments"],
    queryFn: getHubDocuments,
  });
};
