import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { StyleGuide } from "../types";

export const getStyleGuidesForOrg = async (): Promise<StyleGuide[]> => {
  return axios.post("/getStyleGuidesForOrg");
};

type QueryFnType = typeof getStyleGuidesForOrg;

type UseGetStyleGuidesForOrgOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useGetStyleGuidesForOrg = ({
  config,
}: UseGetStyleGuidesForOrgOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["styleGuidesForOrg"],
    queryFn: getStyleGuidesForOrg,
  });
};
