import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";
import { HubFolder } from "../types";

export const createHubFolder = async ({
  name,
}: {
  name: string;
}): Promise<HubFolder> => {
  return axios.post("/createHubFolder", { name });
};

type UseCreateHubFolderOptions = {
  config?: MutationConfig<typeof createHubFolder>;
};

export const useCreateHubFolder = ({
  config,
}: UseCreateHubFolderOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: createHubFolder,
  });
};
