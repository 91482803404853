import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { Button } from "@/components/Elements/Button";
import { Checkbox } from "@/components/Elements/Checkbox";
import { queryClient } from "@/lib/react-query";
import React, { useEffect, useState } from "react";
import { updateTerm } from "../../api/updateTerm";
import { Term } from "../../types";

interface SelectPrimaryTermsDialogProps {
  open: boolean;
  onClose: () => void;
  terms: Term[];
  termLimit: number;
}

const SelectPrimaryTermsDialog: React.FC<SelectPrimaryTermsDialogProps> = ({
  open,
  onClose,
  terms,
  termLimit,
}) => {
  const [selectedTerms, setSelectedTerms] = useState<Term[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // Initialize with currently primary documents
    setSelectedTerms(terms.filter((term) => term.primary));
  }, [terms]);

  const handleToggleDocument = (document: Term) => {
    if (selectedTerms.some((doc) => doc.id === document.id)) {
      setSelectedTerms(selectedTerms.filter((doc) => doc.id !== document.id));
    } else if (selectedTerms.length < termLimit) {
      setSelectedTerms([...selectedTerms, document]);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    for (const term of selectedTerms) {
      await updateTerm({
        hash: term.hash,
        updates: { primary: true },
      });
    }
    queryClient.invalidateQueries(["terms"]);
    onClose();
    setIsSaving(false);
  };

  return (
    <Dialog open={open}>
      <DialogContent className="sm:max-w-[525px] p-0 bg-white dark:bg-zinc-900 border border-zinc-200 dark:border-zinc-700">
        <DialogHeader className="px-6 pt-6 pb-0">
          <DialogTitle className="text-xl font-semibold text-zinc-900 dark:text-zinc-100">
            Select Primary Terms
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="px-6 pt-4">
          <p className="text-sm text-zinc-600 dark:text-zinc-400">
            Your plan supports {termLimit} active terms. Please select which
            ones to keep active. Inactive terms will be saved but won't be
            accessible without upgrading.
          </p>
        </DialogDescription>
        <div className="px-6 py-4 max-h-[400px] overflow-y-auto">
          {terms.map((term) => (
            <div key={term.id} className="flex items-start space-x-2 py-2">
              <Checkbox
                id={`term-${term.id}`}
                checked={selectedTerms.some((t) => t.id === term.id)}
                onCheckedChange={() => handleToggleDocument(term)}
                disabled={
                  (selectedTerms.length >= termLimit &&
                    !selectedTerms.some((doc) => doc.id === term.id)) ||
                  term.primary
                }
              />
              <div className="flex flex-col">
                <label
                  htmlFor={`term-${term.id}`}
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {term.term}
                </label>
              </div>
            </div>
          ))}
        </div>
        <DialogFooter className="px-6 pb-6 flex items-center justify-end space-x-2">
          <Button
            type="button"
            variant="primaryBlur"
            className="bg-blue-600 text-white hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600"
            onClick={handleSave}
            disabled={isSaving || selectedTerms.length !== termLimit}
            isLoading={isSaving}
          >
            Save Selection
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SelectPrimaryTermsDialog;
