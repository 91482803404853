import { axios } from "@/lib/axios";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useMutation } from "react-query";
import { TermTag } from "../types";

export const createTermTag = async ({
  name,
  color,
}: {
  name: string;
  color: string;
}): Promise<TermTag> => {
  return axios.post("/createTermTag", { name, color });
};

type UseCreateTermTagOptions = {
  config?: MutationConfig<typeof createTermTag>;
};

export const useCreateTermTag = ({ config }: UseCreateTermTagOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: createTermTag,
    onSuccess: () => {
      queryClient.invalidateQueries(["termTags"]);
    },
  });
};
