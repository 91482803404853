import { PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import { BrandVoice } from "../../types";
import { BrandVoiceListItem } from "../BrandVoiceListItem";

interface BrandVoiceListProps {
  brandVoices: BrandVoice[];
  onSelectBrandVoice: (brandVoice: BrandVoice | "ADD_NEW") => void;
  selectedBrandVoiceId: number | "ADD_NEW" | null;
  onDeleteBrandVoice: (brandVoice: BrandVoice) => void;
}

export const BrandVoiceList: React.FC<BrandVoiceListProps> = ({
  brandVoices,
  onSelectBrandVoice,
  selectedBrandVoiceId,
  onDeleteBrandVoice,
}) => {
  return (
    <div className="space-y-0">
      <div
        className={`p-3 cursor-pointer transition-colors flex items-center border-b border-gray-200 dark:border-gray-700 ${
          selectedBrandVoiceId === "ADD_NEW"
            ? "bg-emerald-100 dark:bg-emerald-900 text-emerald-800 dark:text-emerald-100"
            : "hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-900 dark:text-gray-100"
        }`}
        onClick={() => onSelectBrandVoice("ADD_NEW")}
      >
        <PlusIcon className="h-5 w-5 mr-2 text-emerald-600 dark:text-emerald-400" />
        <span className="font-medium text-sm">Create New Brand Voice</span>
      </div>
      {brandVoices.map((brandVoice) => (
        <BrandVoiceListItem
          key={brandVoice.id}
          brandVoice={brandVoice}
          onSelect={() => onSelectBrandVoice(brandVoice)}
          isSelected={selectedBrandVoiceId === brandVoice.id}
          deleteBrandVoice={() => onDeleteBrandVoice(brandVoice)}
        />
      ))}
    </div>
  );
};
