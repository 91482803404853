import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { HubDocument } from "../types";

export const getHubDocument = async ({
  hash,
}: {
  hash: string;
}): Promise<HubDocument> => {
  console.log("getHubDocument", { hash });
  return axios.post("/getHubDocument", { hash });
};

type QueryFnType = typeof getHubDocument;

type UseGetHubDocumentOptions = {
  hash: string;
  config?: QueryConfig<QueryFnType>;
};

export const useGetHubDocument = ({
  hash,
  config,
}: UseGetHubDocumentOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["hubDocument", hash],
    queryFn: () => getHubDocument({ hash }),
  });
};
