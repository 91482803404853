"use client";

import { useEditorStore } from "@/stores/editor";
import { cn } from "@/utils/style";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import clsx from "clsx";
import * as React from "react";

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={clsx(
      "inline-flex items-center justify-center bg-zinc-100 rounded-md dark:bg-zinc-800/40",
      className
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    className={clsx(
      "inline-flex items-center justify-center w-full rounded-md px-3 py-0.5 text-2xs font-medium text-zinc-500 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-white data-[state=active]:border dark:data-[state=active]:border-zinc-700 data-[state=active]:text-zinc-900 dark:data-[state=active]:bg-zinc-800 dark:data-[state=active]:text-white hover:text-zinc-900 dark:hover:text-white data-[state=active]:shadow-glow data-[state=active]:ring-1 dark:data-[state=active]:ring-zinc-800 data-[state=active]:ring-zinc-100 ",
      className
    )}
    {...props}
    ref={ref}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

interface TabsContentProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content> {
  isHeaderVisible?: boolean;
  activeTab?: string;
  disableHeightClass?: boolean;
}

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  TabsContentProps
>(
  (
    { className, isHeaderVisible, activeTab, disableHeightClass, ...props },
    ref
  ) => {
    const [atTop, setAtTop] = React.useState(true);
    const { setEditor, editor: editorStore } = useEditorStore();
    const scrollRef = React.useRef<HTMLDivElement>(null);

    const handleScroll = (e) => {
      const atTop = e.target.scrollTop < 10;

      if (props.value === "optimize" && !isHeaderVisible) {
        if (e.target.scrollTop > 100) {
          setEditor((editorState) => {
            editorState.optimizeViewVisible = false;
            editorState.optimizeScrollToTop = false;
          });
        } else {
          setEditor((editorState) => {
            editorState.optimizeViewVisible = true;
          });
        }
      } else {
        setEditor((editorState) => {
          editorState.optimizeViewVisible = false;
          editorState.optimizeScrollToTop = false;
        });
      }

      setAtTop(atTop);
    };

    React.useEffect(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }, [activeTab, editorStore.optimizeScrollToTop]);

    return (
      <TabsPrimitive.Content
        onScroll={handleScroll}
        className={cn(
          "overflow-y-scroll pb-12 overflow-x-hidden",
          !disableHeightClass && "h-[calc(100vh-94px)]",
          !atTop && "border-t dark:border-t-zinc-800",
          className
        )}
        {...props}
        ref={scrollRef}
      />
    );
  }
);

export { Tabs, TabsContent, TabsList, TabsTrigger };
