import { validateUrl } from "@/components/AdvancedEditor/utils/url";
import { getGptOutline } from "@/features/ai/api/getGptOutline";
import { getGptTitle } from "@/features/ai/api/getGptTitle";
import { axiosService } from "@/lib/axios";
import axios from "axios";
import { Cluster } from "../api/getUrlBatch";
import { getContentForUrl } from "../api/processUrl";
import { ProcessedItem, breakDownURL, removeAccents } from "./serp";
import { isQuestion } from "./text";

const classParagraph = (paragraph, sentence, tag) => {
  if (!paragraph || !sentence) return "";

  paragraph = paragraph.trim();
  sentence = sentence.trim();

  if (paragraph === sentence) {
    return `<span class='ent'>${paragraph}</span>`;
  }

  try {
    const searchRegExp = new RegExp(`(\\b${sentence}\\b)`, "gi");
    return paragraph.replace(searchRegExp, "<span class='ent'>$1</span>");
  } catch (err) {
    return paragraph;
  }
};

const is_question = (str, strict) => {
  if (!str || str.length === 0) return false;

  str = removeAccents(str.trim().toLowerCase());

  if (strict) {
    const en_words = [
      "what",
      "which",
      "where",
      "how",
      "why",
      "when",
      "who",
      "can",
      "should",
      "would",
      "is",
      "will",
      "are",
      "do",
      "does",
    ];
    const es_words = ["que", "como", "por que", "quien", "donde", "cuando"];
    const fr_words = ["quoi", "comment", "pourquoi", "qui", "ou", "quand"];
    const it_words = ["cosa", "come", "perché", "chi", "dove", "quando"];
    const de_words = ["was", "wie", "warum", "wer", "wo", "wann"];
    const pt_words = ["o que", "como", "por que", "quem", "onde", "quando"];
    const nl_words = ["wat", "hoe", "waarom", "wie", "waar", "wanneer"];
    const full_list = [
      ...en_words,
      ...es_words,
      ...fr_words,
      ...it_words,
      ...de_words,
      ...pt_words,
      ...nl_words,
    ];

    if (full_list.includes(str.split(" ")[0]) || str.endsWith("?")) {
      return true;
    }
  }

  return str.split(" ").length >= 4 && str.length <= 200;
};

const useBriefFactory = () => {
  const processBrief = (
    processed_items: ProcessedItem[],
    clusters: Cluster[],
    query: string,
    lang: string,
    peopleAlsoAsk: string[],
    docHash: string
  ) => {
    let word_count = 0;
    let valid_items_count = 0;
    let question_list = [];
    let section_list = [];
    let section_count = 0;
    let img_count = 0;
    let img_count_items = 0;
    let link_map = {};
    let domain_map = {};
    let link_count = 0;
    processed_items?.forEach((item, index) => {
      const newItem = { ...item, word_count: item.word_count || 0 };
      if (newItem.word_count) {
        word_count += newItem.word_count;
        valid_items_count += 1;
      }
      let h_section_count = 0;
      let processed_links = [];
      if (newItem.assets) {
        let header_map = {};
        newItem.assets.forEach((value, index) => {
          if (
            value.header &&
            !header_map[value.header.toLowerCase()] &&
            value.header.length > 0 &&
            value.header.length < 120
          ) {
            const is_question = isQuestion(value.header, true);
            const modified_value = { ...value, url: newItem.url };
            if (is_question) {
              question_list.push(modified_value);
            } else if (value.header) {
              section_list.push(modified_value);
            }
            if (
              value &&
              value.header_tag &&
              value.header_tag !== "h1" &&
              value.header_tag.indexOf("h") !== -1
            ) {
              h_section_count += 1;
            }
            header_map[value.header.toLowerCase()] = true;
          }
          value.html.forEach((htmlString, index) => {
            const tempElement = document.createElement("div");
            tempElement.innerHTML = htmlString;

            if (typeof tempElement.querySelectorAll === "function") {
              const a_elem = tempElement.querySelectorAll("a");
              a_elem.forEach((value, index) => {
                if (value.href) {
                  let url: string;
                  if (value.host === document.location.host) {
                    const domain = breakDownURL(newItem.url);
                    url = "https://" + domain + "" + value.pathname + "";
                  } else {
                    url = value.href;
                  }
                  url = url.split("?")[0];
                  url = url.split("#")[0];
                  const parent = value.parentElement.textContent;
                  if (
                    validateUrl(url) === true &&
                    parent.length > 20 &&
                    value.textContent &&
                    value.textContent.trim().length > 0
                  ) {
                    const classed_text = classParagraph(
                      parent,
                      value.textContent,
                      "span"
                    );
                    processed_links.push({
                      anchor: value.textContent,
                      url: url,
                      classed_text: classed_text,
                      raw_text: parent,
                    });
                  }
                }
              });
            }
          });
        });
      }
      if (h_section_count > 10) {
        h_section_count = 10;
      }
      section_count += h_section_count;

      newItem.links = processed_links;
      if (newItem.links && newItem.links.length > 0) {
        const item_domain = breakDownURL(newItem.url);
        newItem.links.forEach((value) => {
          const domain = breakDownURL(value.url);
          if (!domain_map[domain]) {
            domain_map[domain] = {
              items: {},
              internal_links: [],
              external_links: [],
            };
          }
          if (!link_map[value.url]) {
            if (item_domain === domain) {
              domain_map[domain].internal_links.push(value);
            } else {
              domain_map[domain].external_links.push(value);
              link_count += 1;
            }
            link_map[value.url] = true;
          }
          domain_map[domain].items[newItem.url] = true;
        });
      }
      if (newItem.images && newItem.images.length > 0) {
        img_count += newItem.images.length;
        img_count_items += 1;
      }
      return newItem;
    });
    const topics = getTopicsForSERP(clusters);
    const resp = {
      avg_img_count: (img_count / img_count_items).toFixed(0),
      avg_link_count: (link_count / valid_items_count).toFixed(0),
      avg_word_count: (word_count / valid_items_count).toFixed(0),
      avg_section_count: (section_count / valid_items_count).toFixed(0),
      domain_map: domain_map,
      question_list: question_list,
      section_list: section_list,
      sources_processed_count: valid_items_count,
      processed_items: processed_items,
      query: query,
      lang: lang,
      clusters: clusters,
      topics: topics,
      peopleAlsoAsk: peopleAlsoAsk,
      docHash: docHash,
    };
    return resp;
  };

  const getTopicsForSERP = (clusters) => {
    let list = [];
    clusters.forEach((c) => {
      c.cluster_entities.forEach((value) => {
        const updatedValue = {
          ...value,
          frequency: parseInt(value.frequency),
          header_frequency:
            value.header_frequency > 0 && value.header_frequency < 1
              ? 1
              : parseInt(value.header_frequency),
          title_frequency: value.title_count > 0 ? 1 : value.title_frequency,
          long_tail: value.entity.split(" ").length > 1,
          net_score: getTopicScore(value, c.count),
        };
        list.push(updatedValue);
      });
    });
    list = list.sort(sortByItemCount);
    return list;
  };

  const getTopicScore = (value) => {
    let wordCount = value.entity.split(" ").length;
    if (wordCount > 3) wordCount = 3;
    const score =
      (wordCount + value.title_count + value.header_count + value.frequency) *
      value.item_count;
    return score;
  };

  const getSERPLinks = (data) => {
    let domainList = [];
    Object.entries(data.domain_map).forEach(([key, value]) => {
      const itemCount = Object.keys(value.items).length;
      domainList.push({
        domain: key,
        item_count: itemCount,
        internal_links: value.internal_links,
        external_links: value.external_links,
      });
    });
    domainList = domainList.sort(sortByItemCount);
    return domainList;
  };

  const getSERPStatistics = (data) => {
    let statistics = [];
    let statMap = new Set();
    data.processed_items.forEach((item) => {
      if (item.statistics && item.statistics.length > 0) {
        item.statistics.forEach((value) => {
          const statKey = `${value}-${item.url}`;
          if (!statMap.has(statKey)) {
            const source = { url: item.url, title: item.title };
            statistics.push({ source, stat: value });
            statMap.add(statKey);
          }
        });
      }
    });
    return statistics;
  };

  const getSERPQuestions = (data) => data.question_list;

  const getSERPHeaders = (data) => data.section_list;

  const briefSections = [
    {
      description: "High level goals and guidelines.",
      format: "list",
      name: "Guidelines",
      selected: true,
    },
    {
      description: "Related questions asked on Google.",
      format: "list",
      name: "People Also Ask",
      selected: true,
    },
    {
      description: "Search results with titles & descriptions.",
      format: "list",
      name: "SERP",
      selected: true,
    },
    {
      description: "Top 20 topics and clusters across search results.",
      format: "list",
      name: "Topics",
      selected: true,
    },
    {
      description: "Titles generated with AI related to search results.",
      format: "list",
      name: "Titles",
    },
    {
      description:
        "Header structure generated with AI based on search results.",
      format: "list",
      name: "Outline",
      selected: false,
    },
    {
      description: "Relevant questions across search results.",
      format: "list",
      name: "Questions",
      selected: false,
    },
    {
      description: "Factual sentences across search results.",
      format: "list",
      name: "Statistics",
      selected: false,
    },
    {
      description: "Pages that search results link to.",
      format: "list",
      name: "Hyperlinks",
      selected: false,
    },
  ];

  let aiTitles: string[] = [];

  const getBriefSection = async (
    section,
    format,
    data,
    updateSampleText,
    fraseDocument,
    serp,
    cancelToken
  ) => {
    let html = "";

    if (!data) {
      return "";
    }

    if (section === "People Also Ask") {
      const headers = data.peopleAlsoAsk || [];

      if (headers.length > 0) {
        html += "<h2>People Also Ask</h2>";
        html += "<p>Related questions asked on Google:</p>";
        html += "<ul>";
        headers.forEach((value) => {
          html += `<li>${value}</li>`;
        });
        html += "</ul>";
      }
    }

    if (section === "Guidelines") {
      html += "<h2>Overview</h2>";
      html += "<ul>";
      html += "<li>Goal:</li>";
      html += "<li>Target Audience:</li>";
      html += "<li>Deadline:</li>";
      html += "<li>Project Owner:</li>";
      html += `<li>Target Word Count: ${data?.avg_word_count}</li>`;
      html += `<li>Target Headings: ${data?.avg_section_count}</li>`;
      html += "</ul>";
    }

    if (section === "SERP") {
      html += "<h2>SERP</h2>";
      if (format === "table") {
        html += "<table><tbody>";
        html += "<tr>";
        html += "<td style='width: 20%;'>Domain</td>";
        html += "<td style='width: 65%;'>Title and Description</td>";
        html += "<td style='text-align: center; width: 15%;'>Word Count</td>";
        html += "</tr>";
        data.processed_items.forEach((value) => {
          html += "<tr>";
          const domain = breakDownURL(value.url);
          html += `<td style='width: 20%;'><a href="${value.url}">${domain}</a></td>`;
          if (value.description && value.description.length > 0) {
            if (value.description.length > 200) {
              value.description = `${value.description.slice(0, 200)}...`;
            }
            html += `<td style='width: 65%;'><b>${value.title}</b> - ${value.description}</td>`;
          } else {
            html += `<td style='width: 65%;'><b>${value.title}</b></td>`;
          }
          if (value.word_count && value.word_count > 100) {
            html += `<td style='text-align: center; width: 15%;'>${value.word_count}</td>`;
          } else {
            html += "<td style='text-align: center; width: 15%;'>-</td>";
          }
          html += "</tr>";
        });
        html += "</tbody></table>";
      } else {
        html += "<ul>";
        data.processed_items.forEach((value) => {
          html += `<li><strong>${value.title}</strong>`;
          if (value.description && value.description.length > 0) {
            if (value.description.length > 300) {
              value.description = `${value.description.slice(0, 300)}...`;
            }
            html += ` - ${value.description}`;
          }
          const domain = breakDownURL(value.url);
          html += ` <a href="${value.url}">(${domain})</a>`;
          html += "</li>";
        });
        html += "</ul>";
      }
    }

    if (section === "Topics") {
      html += "<h2>Top 20 Topics</h2>";
      html += "<p>Topics sorted by frequency across top search results:</p>";
      const filteredTopics = data.topics;
      if (format === "table") {
        html += "<table><tbody>";
        html += "<tr>";
        html += "<td style='width: 50%;'>Topic</td>";
        html += "<td style='text-align: center; width: 25%;'>Sources</td>";
        html += "<td style='text-align: center; width: 25%;'>Mentions</td>";
        html += "</tr>";
        filteredTopics.slice(0, 25).forEach((value) => {
          html += "<tr>";
          html += `<td style='width: 50%;'>${value.entity}</td>`;
          html += `<td style='text-align: center; width: 25%;'>${value.item_count}</td>`;
          html += `<td style='text-align: center; width: 25%;'>${value.frequency}</td>`;
          html += "</tr>";
        });
        html += "</tbody></table>";
      } else {
        html += "<ul>";
        filteredTopics.slice(0, 25).forEach((value) => {
          html += `<li>${value.entity}</li>`;
        });
        html += "</ul>";
      }

      html += "<h2>Topic Clusters</h2>";
      html +=
        "<p>Topics referenced across search results organized in clusters:</p>";
      const clusters = data.clusters;
      if (format === "table") {
        html += "<table><tbody>";
        html += "<tr>";
        html += "<td style='width: 30%;'>Cluster Label</td>";
        html += "<td style='width: 70%;'>Topics</td>";
        html += "</tr>";
        clusters.slice(0, 10).forEach((value) => {
          html += "<tr>";
          html += `<td style='width: 40%;'>${value.label}</td>`;
          html += "<td style='width: 70%;'><ul>";
          value.cluster_entities.slice(0, 10).forEach((entity) => {
            html += `<li>${entity.entity}</li>`;
          });
          html += "</ul></td>";
          html += "</tr>";
        });
        html += "</tbody></table>";
      } else {
        clusters.slice(0, 10).forEach((value) => {
          html += `<p><span style='background-color: rgb(251, 238, 184);'>${value.label}</span></p>`;
          html += "<ul>";
          value.cluster_entities.slice(0, 10).forEach((entity) => {
            html += `<li>${entity.entity}</li>`;
          });
          html += "</ul>";
        });
      }
    }

    if (section === "Titles") {
      const resp = await getGptTitle({
        query: data.query,
        lang: data.lang,
        serp: data.processed_items,
        docHash: data.docHash,
      });

      if (resp.titles.length > 0) {
        html += "<h2>Titles</h2>";
        html += "<p>Titles generated with AI related to search results:</p>";
        html += "<ul>";
        resp.titles.forEach((title) => {
          html += `<li>${title}</li>`;
        });
        html += "</ul>";
      }
      aiTitles = resp.titles;
      updateSampleText(section, html);
      return;
    }

    if (section === "Outline") {
      const aiTitle = aiTitles[0] || fraseDocument?.text?.[0]?.title || "";
      try {
        const aiHeaders = await getGptOutline({
          title: aiTitle,
          topics: data.topics.map((t) => t.entity).join(", "),
          lang: data.lang,
          serp: serp.results,
          docHash: data.docHash,
        });
        if (aiHeaders.outline.length > 0) {
          html += "<h2>Outline</h2>";
          html +=
            "<p>Header structure generated with AI based on search results:</p>";
          aiHeaders.outline.forEach((header) => {
            html += `<${header.user_header_tag}>${header.header}</${header.user_header_tag}>`;
          });
        }
      } catch (error) {
        console.error("Error fetching GPT outline:", error);
        html += "<p>Failed to generate outline. Please try again later.</p>";
      }
      updateSampleText(section, html);
      return;
    }

    if (section === "Questions") {
      if (format !== "table") {
        const questions = getSERPQuestions(data);
        const uniqueQuestions = Array.from(
          new Map(
            questions.map((q) => [q.header.toLowerCase(), q.header])
          ).values()
        );
        if (uniqueQuestions.length > 0) {
          html += "<h2>Questions</h2>";
          html += "<p>Questions used across top search results:</p>";
          html += "<ul>";
          uniqueQuestions.slice(0, 10).forEach((header) => {
            html += `<li>${header}</li>`;
          });
          html += "</ul>";
        }
      } else {
        const headersByTopic = getHeadersByTopic(data);
        if (headersByTopic.question_list.length > 0) {
          html += "<p></p>";
          html += "<h2>Questions</h2>";
          html += "<p>Questions used across top search results:</p>";
          html += "<table><tbody>";
          html += "<tr>";
          html += "<td style='width: 25%;'>Topic</td>";
          html += "<td style='width: 75%;'>Questions</td>";
          html += "</tr>";
          headersByTopic.question_list.slice(0, 6).forEach((value) => {
            if (value.list.length > 0) {
              const uniqueQuestions = Array.from(
                new Map(value.list.map((q) => [q.toLowerCase(), q])).values()
              );
              html += "<tr>";
              html += `<td style='width: 25%;'>${value.topic}</td>`;
              html += "<td style='width: 75%;'>";
              uniqueQuestions.slice(0, 4).forEach((q) => {
                html += `<p>${q}</p>`;
              });
              html += "</td>";
              html += "</tr>";
            }
          });
          html += "</tbody></table>";
        }
      }
    }

    if (section === "Statistics") {
      const statisticsList = getSERPStatistics(data);
      if (statisticsList.length > 0) {
        html += "<h2>Statistics</h2>";
        html +=
          "<p>Factual sentences referenced across top search results:</p>";
        if (format === "table") {
          html += "<table><tbody>";
          statisticsList.slice(0, 10).forEach((value) => {
            const domain = breakDownURL(value.source.url);
            html += `<tr><td>${value.stat} (<a href="${value.source.url}">${domain}</a>)</td></tr>`;
          });
          html += "</tbody></table>";
        } else {
          html += "<ul>";
          statisticsList.slice(0, 10).forEach((value) => {
            const domain = breakDownURL(value.source.url);
            html += `<li>${value.stat} (<a href="${value.source.url}">${domain}</a>)</li>`;
          });
          html += "</ul>";
        }
      }
    }

    if (section === "Hyperlinks") {
      const domainList = getSERPLinks(data);
      if (domainList.length > 0) {
        html += "<h2>External Links</h2>";
        html +=
          "<p>Pages that search results are linking to (excluding internal links):</p>";
        const reqList = [];
        let count = 0;

        domainList.forEach((value) => {
          value.external_links.slice(0, 2).forEach((link) => {
            if (count < 15) {
              reqList.push(getContentForUrl(link.url, cancelToken));
              count += 1;
            }
          });
        });

        try {
          const values = await Promise.all(reqList);
          const map = {};
          const domainMap = {};
          values.forEach((value) => {
            if (
              value &&
              value.url &&
              value.title &&
              !map[value.title] &&
              !map[value.url] &&
              !["not found", "404", "500"].some((str) =>
                value.title.toLowerCase().includes(str)
              )
            ) {
              const domain = breakDownURL(value.url);
              if (!domainMap[domain]) {
                domainMap[domain] = [];
              }
              domainMap[domain].push(value);
              map[value.title] = true;
              map[value.url] = true;
            } else {
              console.error("Invalid value:", value);
            }
          });

          let list = [];
          Object.entries(domainMap).forEach(([domain, links]) => {
            if (links.length > 0) {
              list.push({ domain, links, count: links.length });
            }
          });
          list = list.sort(sortByCount);

          if (list.length > 0) {
            if (format === "table") {
              html += "<table><tbody>";
              html += "<tr>";
              html += "<td style='width: 25%;'>Domain</td>";
              html += "<td style='width: 75%;'>Links</td>";
              html += "</tr>";
              list.slice(0, 10).forEach((domain) => {
                if (domain.links.length > 0) {
                  html += "<tr>";
                  html += `<td style='width: 25%;'><a target='_blank' href='https://${domain.domain}'>${domain.domain}</a></td>`;
                  html += "<td style='width: 75%;'><ul>";
                  domain.links.forEach((link) => {
                    html += `<li><a target='_blank' href='${link.url}'>${link.title}</a></li>`;
                  });
                  html += "</ul></td>";
                  html += "</tr>";
                }
              });
              html += "</tbody></table>";
            } else {
              list.slice(0, 10).forEach((domain) => {
                if (domain.links.length > 0) {
                  html += `<p><a target='_blank' href='https://${domain.domain}'><b>${domain.domain}</b></a></p>`;
                  html += "<ul>";
                  domain.links.forEach((link) => {
                    html += `<li><a target='_blank' href='${link.url}'>${link.title}</a></li>`;
                  });
                  html += "</ul>";
                }
              });
            }
          }
          updateSampleText(section, html);
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log("Request canceled:", error.message);
          } else {
            console.error("Error processing URLs:", error);
          }
        }
      }
    }

    updateSampleText(section, html);
  };

  const getHeadersByTopic = (data) => {
    const headerTopics = entitiesFactory.filterTopics(data.topics, "Headers");
    const headerTopicMap = {};
    const headerMap = {};
    const headerList = [];
    const questionTopicMap = {};
    const questionMap = {};
    const questionList = [];

    headerTopics.forEach((topic) => {
      if (topic.entity && topic.entity.split(" ").length > 1) {
        data.section_list.forEach((value) => {
          if (value.header.toLowerCase().includes(topic.sing_lower)) {
            if (!headerTopicMap[topic.sing_lower]) {
              headerTopicMap[topic.sing_lower] = [];
            }
            if (
              !headerMap[value.header.toLowerCase()] &&
              headerTopicMap[topic.sing_lower].length < 3
            ) {
              headerTopicMap[topic.sing_lower].push(value.header);
              headerMap[value.header.toLowerCase()] = true;
            }
          }
        });
        data.question_list.forEach((value) => {
          if (value.header.toLowerCase().includes(topic.sing_lower)) {
            if (!questionTopicMap[topic.sing_lower]) {
              questionTopicMap[topic.sing_lower] = [];
            }
            if (
              !questionMap[value.header.toLowerCase()] &&
              questionTopicMap[topic.sing_lower].length < 3
            ) {
              questionTopicMap[topic.sing_lower].push(value.header);
              questionMap[value.header.toLowerCase()] = true;
            }
          }
        });
      }
    });

    Object.entries(headerTopicMap).forEach(([key, value]) => {
      if (value.length > 0) {
        headerList.push({ topic: key, list: value, count: value.length });
      }
    });
    Object.entries(questionTopicMap).forEach(([key, value]) => {
      questionList.push({ topic: key, list: value, count: value.length });
    });

    headerList.sort(sortByCount);
    questionList.sort(sortByCount);

    return {
      header_list: headerList,
      header_topic_map: headerTopicMap,
      question_list: questionList,
      question_topic_map: questionTopicMap,
    };
  };

  const getIntentForSerp = (items, query, topics) => {
    const intents = {
      "how to": {
        desc: "Step by step guides and educational content",
        score: 0,
      },
      listicle: {
        desc: "Content presented wholly or partly in the form of a list",
        score: 0,
      },
      "long form": { desc: "Content with more than 1,500 words", score: 0 },
      ecommerce: { desc: "Product pages for ecommerce", score: 0 },
      "landing page": {
        desc: "Company landing/pillar pages, and content hubs",
        score: 0,
      },
      visual: { desc: "Content than includes over 2 images", score: 0 },
      video: { desc: "Content than includes at least 1 video", score: 0 },
      review: {
        desc: "Content that offers a review or comparison between products",
        score: 0,
      },
      local: { desc: "Content where geographic context is key", score: 0 },
    };

    query = query.toLowerCase();
    items.forEach((value, index) => {
      if (value.listicle) {
        intents.listicle.score += 1;
        if (index < 10) intents.listicle.score += 1;
      }
      if (value.how_to) {
        intents["how to"].score += 1;
        if (index < 10) intents["how to"].score += 1;
      }
      if (value.long_form) {
        intents["long form"].score += 1;
        if (index < 10) intents["long form"].score += 1;
      }
      if (value.product || value.ecommerce) {
        intents.ecommerce.score += 1;
        if (index < 10) intents.ecommerce.score += 1;
      }
      if (value.landing_page) {
        intents["landing page"].score += 1;
        if (index < 10) intents["landing page"].score += 1;
      }
      if (value.img_count > 2 && !value.long_form) {
        intents.visual.score += 1;
        if (index < 10) intents.visual.score += 1;
      }
      if (value.iframe_count > 2) {
        intents.video.score += 1;
        if (index < 10) intents.video.score += 1;
      }
      if (value.review) {
        intents.review.score += 1;
        if (index < 10) intents.review.score += 1;
      }
      if (value.entities && value.entities.length > 0) {
        let locationEntity = false;
        value.entities.forEach((entity) => {
          const ent = entity.entity.toLowerCase();
          if (
            entity.type === "LOCATION" &&
            isEntityOnQueryAndTitle(ent, query, value.title.toLowerCase())
          ) {
            locationEntity = true;
          }
        });
        if (locationEntity) {
          intents.local.score += 1;
          if (index < 6) intents.local.score += 1;
        }
      }
    });

    const list = [];
    Object.entries(intents).forEach(([key, value]) => {
      let percent = ((value.score / (items.length * 0.5)) * 100).toFixed(1);
      if (percent > 100) percent = 100;
      if (percent > 10) {
        list.push({ name: key, count: value.score, percent, desc: value.desc });
      }
    });
    list.sort(sortByCount);
    return list;
  };

  const isEntityOnQueryAndTitle = (entity, query, title) =>
    query.includes(entity) && title.includes(entity);

  const sortByScore = (a, b) => b.net_score - a.net_score;

  const sortByItemCount = (a, b) => b.item_count - a.item_count;

  const sortByCount = (a, b) => b.count - a.count;

  return {
    processBrief,
    getTopicsForSERP,
    getSERPLinks,
    getSERPStatistics,
    getSERPQuestions,
    getSERPHeaders,
    briefSections,
    getBriefSection,
    getHeadersByTopic,
    getIntentForSerp,
  };
};

const processUrl = async (url, skip_cache) => {
  const params = {
    url: url,
    timeout: 20,
    include_full_text: true,
    skip_cache: skip_cache,
  };
  try {
    const resp = await axiosService.post("/processURL", params);
    return resp;
  } catch {
    return "error";
  }
};

export default useBriefFactory;
