/**
 * Example Args
 * {
 "ruleId": 1,
 "guideId": 2,
 "enabled": true,
 "booleanValue": false,
 "numericValue": 30.0, 
 "choiceValue": "plus",
 "exceptions": ["new-pattern1", "new-pattern2"]
}

 */

import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";
import { StyleGuideRule } from "../types";

export const saveStyleGuideRule = async (
  rule: Omit<StyleGuideRule, "id" | "updatedAt">
): Promise<StyleGuideRule> => {
  return axios.post("/saveStyleGuideRule", rule);
};

type UseSaveStyleGuideRuleOptions = {
  config?: MutationConfig<typeof saveStyleGuideRule>;
};

export const useSaveStyleGuideRule = ({
  config,
}: UseSaveStyleGuideRuleOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: saveStyleGuideRule,
  });
};
