import { useGetTermTags } from "./api/getTermTags";

import { useGetTerms } from "./api/getTerms";

import { useMemo } from "react";
import { useSubscription } from "../auth/api/getSubscription";

export const TERM_LIMITS = {
  free: 10,
  solo: 10,
  basic: 30,
  team: 100,
  enterprise: Infinity,
} as const;

const getTermLimit = (plan?: string, appsumo?: boolean): number => {
  if (appsumo) return TERM_LIMITS.team;
  if (!plan || plan.includes("solo")) return TERM_LIMITS.free;
  if (plan.includes("basic")) return TERM_LIMITS.basic;
  if (plan.includes("team")) return TERM_LIMITS.team;
  return TERM_LIMITS.free;
};

export const useTerms = () => {
  const { data: subscription, isLoading: isLoadingSubscription } =
    useSubscription({});
  const { data: allTerms, isLoading: isLoadingTerms } = useGetTerms();
  const { data: termTags, isLoading: isLoadingTags } = useGetTermTags();

  const termLimit = getTermLimit(
    subscription?.plan,
    subscription?.appsumo ?? false
  );

  const currentTermCount = useMemo(() => {
    return allTerms?.length ?? 0;
  }, [allTerms]);

  const isLoading = useMemo(
    () => isLoadingTerms || isLoadingTags || isLoadingSubscription,
    [isLoadingTerms, isLoadingTags, isLoadingSubscription]
  );

  const overLimit = currentTermCount > termLimit;

  const useableTerms = useMemo(() => {
    if (overLimit) return allTerms?.filter((term) => term.primary);
    return allTerms;
  }, [allTerms, overLimit]);

  const needToSetPrimary = useMemo(() => {
    return overLimit && !useableTerms?.length;
  }, [overLimit, useableTerms]);

  return {
    allTerms,
    isLoading,
    termLimit,
    currentTermCount,
    overLimit,
    termTags,
    needToSetPrimary,
    useableTerms,
  };
};
