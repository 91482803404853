import { Filter } from "@/components/Elements/TableFilter";
import { Tag } from "@/components/Elements/Tag";
import { SelectCell } from "@/components/Elements/TemplateTable/SelectCell";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements/Tooltip";
import { useCreateTermTag } from "@/features/terms/api/createTermTag";
import {
  UpdateTermFields,
  useUpdateTerm,
} from "@/features/terms/api/updateTerm";
import { Term } from "@/features/terms/types";
import { useNotificationStore } from "@/stores/notifications";
import { DocumentIcon } from "@heroicons/react/24/solid";
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { Plus, TagIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { TermTagsCell } from "../../TermTagsCell";
import { TermsTableProps } from "../types";

export const useTermsTable = (props: TermsTableProps) => {
  const { terms = [], tags = [], overLimit } = props;
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationStore();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<Filter[]>([]);
  const [termsToTag, setTermsToTag] = useState<Term[]>([]);
  const [page, setPage] = useState(1);

  const updateTermMutation = useUpdateTerm();
  const createTagMutation = useCreateTermTag();

  const [filteredTerms, setFilteredTerms] = useState<Term[]>([]);

  useEffect(() => {
    const _filteredTerms = terms
      .filter((term) => {
        return selectedFilters.every((filter) => {
          if (filter.parent === "tags") {
            return term.tags?.some((tag) => tag.id === parseInt(filter.value));
          }

          return term[filter.parent as keyof Term]
            ?.toString()
            .toLowerCase()
            .includes(filter.value.toString().toLowerCase());
        });
      })
      .sort((a, b) => {
        if (overLimit) {
          // If overLimit is true, sort primary terms to the top
          if (a.primary !== b.primary) {
            return a.primary ? -1 : 1;
          }
        }

        // Sort alphabetically by term
        const termComparison = a.term.localeCompare(b.term);

        // If terms are the same, sort by createdAt ascending
        if (termComparison === 0) {
          return a.createdAt - b.createdAt;
        }

        return termComparison;
      });

    setFilteredTerms(_filteredTerms);
  }, [terms, selectedFilters]);

  const handleOptimisticUpdate = (
    hash: string,
    updates: Omit<Partial<Term>, "tags"> & {
      tags?: { id: number }[];
    }
  ) => {
    if (updates.tags) {
      updates.tags = updates.tags.map((_tag) => {
        const tag = tags.find((t) => t.id === _tag.id);
        return tag!;
      });
    }
    setFilteredTerms((currentTerms) =>
      currentTerms.map((term) =>
        term.hash === hash ? { ...term, ...(updates as Partial<Term>) } : term
      )
    );
  };

  const columns: ColumnDef<Term>[] = [
    {
      id: "select",
      meta: { label: "Select" },
      header: SelectCell.Header,
      cell: SelectCell.Cell,
      enableSorting: false,
      enableHiding: false,
      size: 0,
    },
    {
      accessorKey: "term",
      meta: { label: "Term" },
      header: () => {
        return <p className="text-xs">Term</p>;
      },
      cell: ({ row }) => {
        const isGreyedOut = overLimit && !row.original.primary;
        return (
          <div className="flex items-center space-x-2">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <p
                    className={`text-xs font-medium ${
                      isGreyedOut
                        ? "text-gray-400 dark:text-gray-500"
                        : "dark:text-white"
                    }`}
                  >
                    <strong>{row.original.term}</strong>
                  </p>
                </TooltipTrigger>
                {isGreyedOut && (
                  <TooltipContent>
                    This term is inactive due to plan limits. Upgrade your plan
                    to use it with the AI tools.
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
            {overLimit && row.original.primary && (
              <Tag color="emerald">Primary</Tag>
            )}
          </div>
        );
      },
      size: 250,
    },
    {
      accessorKey: "termRule",
      meta: {
        label: "Rule",
      },
      header: () => <p className="text-xs">Rule</p>,
      cell: ({ row }) => {
        const formatTermRule = (rule: string) => {
          switch (rule) {
            case "PENDING":
              return {
                text: "Pending",
                color: "bg-yellow-100 text-yellow-800",
              };
            case "DONT_USE":
              return { text: "Don't Use", color: "bg-red-100 text-red-800" };
            case "USE_CAREFULLY":
              return {
                text: "Use Carefully",
                color: "bg-orange-100 text-orange-800",
              };
            case "APPROVED":
              return { text: "Approved", color: "bg-green-100 text-green-800" };
            default:
              return { text: rule, color: "bg-gray-100 text-gray-800" };
          }
        };

        const { text, color } = formatTermRule(row.original.termRule);

        return (
          <span
            className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${color}`}
          >
            {text}
          </span>
        );
      },
    },
    {
      accessorKey: "partOfSpeech",
      meta: {
        label: "Part of Speech",
      },
      header: () => <p className="text-xs">Part of Speech</p>,
      cell: ({ row }) => {
        if (!row.original.partOfSpeech) {
          return <p className="text-xs">N/A</p>;
        }
        return (
          <p className="text-xs">
            {row.original.partOfSpeech.charAt(0).toUpperCase() +
              row.original.partOfSpeech.slice(1).toLowerCase()}
          </p>
        );
      },
    },
    {
      accessorKey: "description",
      meta: {
        label: "Description",
      },
      header: () => <p className="text-xs">Description</p>,
      size: 300,
    },
    {
      accessorKey: "updatedAt",
      meta: {
        label: "Edited",
      },
      header: () => <p className="text-xs">Edited</p>,
      cell: ({ row }) => {
        return (
          <p className="text-2xs font-normal text-zinc-600 dark:text-zinc-400">
            {dayjs().to(dayjs(row.getValue("updatedAt")))}
          </p>
        );
      },
      size: 130,
    },
    {
      accessorKey: "tags",
      meta: {
        label: "Tags",
      },
      header: () => <p className="text-xs">Tags</p>,
      cell: ({ row }) => (
        <TermTagsCell
          term={row.original}
          // @ts-ignore
          tags={processedTags}
          onFieldUpdate={onFieldUpdate}
        />
      ),
      size: 400,
    },
  ];

  const handleCreateTag = async (
    tagName: string,
    color: string,
    data: Term | Term[]
  ) => {
    setIsCreatingTag(true);
    try {
      const tagResponse = await createTagMutation.mutateAsync({
        name: tagName,
        color,
      });
      if (Array.isArray(data)) {
        data.forEach((doc) => {
          const existingTags = doc.tags?.map((tag) => ({ id: tag.id })) || [];
          onFieldUpdate(
            [
              {
                field: "tags",
                value: [...existingTags, { id: tagResponse.id }],
              },
            ],
            doc
          );
        });
      } else {
        const existingTags = data.tags?.map((tag) => ({ id: tag.id })) || [];
        onFieldUpdate(
          [{ field: "tags", value: [...existingTags, { id: tagResponse.id }] }],
          data
        );
      }

      await queryClient.invalidateQueries(["termTags"]);

      addNotification({
        type: "success",
        title: "Tag created",
        message: `Tag "${tagName}" has been created successfully.`,
      });
    } catch (error) {
      console.error("Error creating tag:", error);
      addNotification({
        type: "error",
        title: "Failed to create tag",
        message: "An error occurred while creating the tag.",
      });
    } finally {
      setIsCreatingTag(false);
    }
  };

  const processedTags = useMemo(() => {
    const tagOptions = tags.map(
      (tag) => ({
        label: tag.name,
        value: tag.id.toString(),
        color: tag.color,
        onClick: (
          _value: any,
          selectedRowsOrDocument: { original: Term }[] | Term
        ) => {
          if (Array.isArray(selectedRowsOrDocument)) {
            selectedRowsOrDocument.forEach((row) => {
              const existingTags =
                row.original.tags?.map((tag) => ({ id: tag.id })) || [];
              onFieldUpdate(
                [{ field: "tags", value: [...existingTags, { id: tag.id }] }],
                row.original
              );
            });
          } else {
            const existingTags =
              selectedRowsOrDocument.tags?.map((tag) => ({ id: tag.id })) || [];
            onFieldUpdate(
              [{ field: "tags", value: [...existingTags, { id: tag.id }] }],
              selectedRowsOrDocument
            );
          }
        },
        icon: <TagIcon className="w-3 h-3 mr-2" />,
        type: "button",
      }),
      [tags]
    );

    const newTagOption = {
      value: "New Tag",
      label: "New Tag",
      isLoading: isCreatingTag,
      onClick: (
        _value: any,
        selectedRowsOrDocument: { original: Term }[] | Term
      ) => {
        const docs = Array.isArray(selectedRowsOrDocument)
          ? selectedRowsOrDocument.map((row) => row.original)
          : [selectedRowsOrDocument];
        setTermsToTag(docs);
      },
      icon: <Plus className="w-3 h-3 mr-2" />,
      type: "button",
    };

    return [newTagOption, ...tagOptions];
  }, [tags, isCreatingTag]);

  const table = useReactTable({
    data: filteredTerms,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination: {
        pageIndex: page - 1,
        pageSize: 20,
      },
    },
    onPaginationChange: (updater) => {
      if (typeof updater === "function") {
        const newState = updater(table.getState().pagination);
        setPage(newState.pageIndex + 1);
      } else {
        setPage(updater.pageIndex + 1);
      }
    },
  });

  const onFieldUpdate = (
    updates: { field: keyof UpdateTermFields; value: any }[],
    data: Term | Term[]
  ) => {
    const updateDocumentField = async (
      doc: Term,
      updatesArray: { field: keyof UpdateTermFields; value: any }[]
    ) => {
      try {
        const updates = updatesArray.reduce(
          (acc, update) => ({ ...acc, [update.field]: update.value }),
          {}
        );
        handleOptimisticUpdate(doc.hash, updates);
        await updateTermMutation.mutateAsync({
          hash: doc.hash,
          updates,
        });
      } catch (error) {
        addNotification({
          type: "error",
          title: "Term update failed",
          message: `We encountered an issue while updating the term "${doc.term}". Please try again.`,
        });
      }
    };

    const handleUpdate = async (doc: Term) => {
      await updateDocumentField(doc, updates);
    };

    if (Array.isArray(data)) {
      // Optimistically update the state for all selected documents
      data.forEach((doc) => {
        handleUpdate(doc);
      });

      // Immediately deselect all rows after optimistic update
      table.toggleAllPageRowsSelected(false);
    } else {
      handleUpdate(data);
      // Immediately deselect the row after optimistic update
      table.toggleAllPageRowsSelected(false);
    }
  };

  const filters = useMemo<Filter[]>(
    () => [
      {
        value: "term",
        label: "Term",
        icon: <DocumentIcon />,
        parent: "term",
        type: "input",
      },
      {
        label: "Tag",
        type: "filter",
        value: "tags",
        children: tags.map((tag) => ({
          label: tag.name,
          value: tag.id.toString(),
          icon: <TagIcon />,
          parent: "tags",
          type: "filter",
        })),
        icon: <TagIcon />,
      },
    ],
    [tags]
  );

  const removeFilter = (idx: number) => {
    const newFilters = [...selectedFilters];
    newFilters.splice(idx, 1);
    setSelectedFilters(newFilters);
  };

  const closeCreateHubTagDialog = () => {
    setTermsToTag([]);
  };

  const finalizeCreateHubTag = async (tagName: string, color: string) => {
    await handleCreateTag(tagName, color, termsToTag);
    closeCreateHubTagDialog();
  };

  const isCreateHubTagDialogOpen = termsToTag.length > 0;

  return {
    filters,
    table,
    processedTags,
    selectedFilters,
    removeFilter,
    setSelectedFilters,
    isCreateHubTagDialogOpen,
    finalizeCreateHubTag,
    closeCreateHubTagDialog,
    page,
    setPage,
    handleOptimisticUpdate,
  };
};
