import { Button } from "@/components/Elements/Button";
import { FraseResearchBot } from "@/components/Elements/Logo/FraseResearchBot";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Elements/Table";
import { ContentLayout } from "@/components/Layout";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clearPrimaryTerms } from "../api/clearPrimaryTerms";
import { insertDefaultTermsIfNeverDoneBefore } from "../api/insertDefaultTerms";
import SelectPrimaryTermsDialog from "../components/SelectPrimaryTermsDialog";
import TermsTable from "../components/TermsTable";
import UpgradeToContinueDialog from "../components/UpgradeToContinueDialog";
import { TERM_LIMITS, useTerms } from "../hook";
import { Term } from "../types";
// import SelectPrimaryDocumentsDialog from "../components/SelectPrimaryDocumentsDialog";
// import UpgradeToContinueDialog from "../components/UpgradeToContinueDialog";

const Terms = () => {
  const {
    allTerms: terms,
    termTags: tags,
    termLimit,
    currentTermCount,
    isLoading,
  } = useTerms();
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const [showSelectPrimaryDialog, setShowSelectPrimaryDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    insertDefaultTermsIfNeverDoneBefore();
  }, []);

  useEffect(() => {
    const checkDocuments = async () => {
      if (!isLoading && terms && termLimit > 0) {
        const primaryDocs = terms.filter((doc) => doc.primary);
        const primaryCount = primaryDocs.length;

        if (currentTermCount > termLimit) {
          if (primaryCount > termLimit) {
            // Too many primary documents
            await clearPrimaryTerms();
            setShowSelectPrimaryDialog(true);
          } else if (primaryCount < termLimit) {
            // Not enough primary documents
            setShowSelectPrimaryDialog(true);
          }
        }
      }
    };

    checkDocuments();
  }, [isLoading, terms, termLimit, currentTermCount]);

  const checkForDuplicate = useCallback(
    (termToCheck: Partial<Term>) => {
      let termsToCheckAgainst: Term[] = terms || [];
      if (termToCheck.id) {
        termsToCheckAgainst =
          terms?.filter((term) => term.id !== termToCheck.id) || [];
      }

      const duplicate = termsToCheckAgainst?.some((term) => {
        return (
          term.term.toLowerCase().trim() ===
            termToCheck.term?.toLowerCase().trim() &&
          term.partOfSpeech === termToCheck.partOfSpeech
        );
      });

      return duplicate;
    },
    [terms]
  );

  if (isLoading) {
    return (
      <ContentLayout title="Terms">
        <div className="relative flex items-center w-full h-full justify-center">
          <LogoSpinner variant="md" loadingText="Loading Terms..." />
        </div>
      </ContentLayout>
    );
  }

  if (termLimit === 0) {
    return (
      <ContentLayout title="Terms">
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="bg-white dark:bg-zinc-800 rounded-md p-10 shadow-glow max-w-2xl border space-y-6 dark:border-zinc-700">
            <FraseResearchBot />
            <div className="space-y-6">
              <div className="space-y-4">
                <h1 className="text-2xl font-medium dark:text-white">
                  Welcome to the Frase Knowledge Hub
                </h1>
                <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                  Use internal knowledge to generate content that's accurate and
                  uniquely yours. Frase pulls insights directly from your
                  documents for original, relevant content that goes beyond
                  generic AI.
                </p>
              </div>

              <div className="space-y-4">
                <h2 className="text-lg font-medium dark:text-white">
                  Here's how customers use the Knowledge Hub to elevate their
                  content:
                </h2>
                <ul className="space-y-2 text-[15px] text-zinc-600 dark:text-zinc-200 pl-6">
                  <li className="list-disc">
                    Generate insightful blog posts and articles based on your
                    data.
                  </li>
                  <li className="list-disc">
                    Simplify complex topics with custom guides and how-tos.
                  </li>
                  <li className="list-disc">
                    Craft product pages that reflect your unique specs.
                  </li>
                </ul>
                <div style={{ marginTop: "26px" }}>
                  <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                    Power your content with Frase for deeper accuracy and
                    impact.
                  </p>
                </div>
              </div>

              <Table className="w-full mb-4">
                <TableHeader className="bg-zinc-50 dark:bg-zinc-800">
                  <TableRow>
                    <TableHead className="w-1/2 py-1 px-4 text-left text-xs font-semibold text-zinc-900 dark:text-white">
                      Plan
                    </TableHead>
                    <TableHead className="w-1/2 py-1 px-4 text-right text-xs font-semibold text-zinc-900 dark:text-white">
                      Documents
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                      Free
                    </TableCell>
                    <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                      {TERM_LIMITS.free}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                      Basic
                    </TableCell>
                    <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                      {TERM_LIMITS.basic}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                      Team
                    </TableCell>
                    <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                      {TERM_LIMITS.team}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                      Enterprise
                    </TableCell>
                    <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                      Unlimited
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Button
                variant="primaryBlur"
                size="sm"
                className="bg-emerald-100 w-full"
                onClick={() => {
                  navigate("/app/settings/plans");
                }}
              >
                Upgrade your plan to power your content with Frase
              </Button>
            </div>
          </div>
        </div>
      </ContentLayout>
    );
  }

  return (
    <>
      <ContentLayout title="Terms">
        <TermsTable
          terms={terms}
          tags={tags}
          termLimit={termLimit}
          currentTermCount={currentTermCount}
          onLimitReached={() => setShowUpgradeDialog(true)}
          checkForDuplicate={checkForDuplicate}
        />
      </ContentLayout>
      <SelectPrimaryTermsDialog
        open={showSelectPrimaryDialog}
        onClose={() => setShowSelectPrimaryDialog(false)}
        terms={terms || []}
        termLimit={termLimit}
      />
      <UpgradeToContinueDialog
        open={showUpgradeDialog}
        onClose={() => setShowUpgradeDialog(false)}
        onCancel={() => setShowUpgradeDialog(false)}
        currentTermCount={currentTermCount}
        termLimit={termLimit}
      />
    </>
  );
};

export default Terms;
