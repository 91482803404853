import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";
import {
  PartOfSpeech,
  Term,
  TermExample,
  TermMistake,
  TermRule,
} from "../types";

export type UpdateTermFields = {
  tags?: { id: number }[] | null;
  name?: string;
  description?: string;
  partOfSpeech?: PartOfSpeech;
  termRule?: TermRule;
  caseSensitive?: boolean;
  mistakes?: TermMistake[];
  examples?: TermExample[];
  primary?: boolean;
};

export const updateTerm = async ({
  hash,
  updates,
}: {
  hash: string;
  updates: UpdateTermFields;
}): Promise<Term> => {
  return axios.post("/updateTerm", { hash, ...updates });
};

type UseUpdateTermOptions = {
  config?: MutationConfig<typeof updateTerm>;
};

export const useUpdateTerm = ({ config }: UseUpdateTermOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: updateTerm,
  });
};
