import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { BrandVoice } from "../types";

export const getBrandVoicesForOrg = async (): Promise<BrandVoice[]> => {
  return axios.post("/getBrandVoicesForOrg");
};

type QueryFnType = typeof getBrandVoicesForOrg;

type UseGetBrandVoicesForOrgOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useGetBrandVoicesForOrg = ({
  config,
}: UseGetBrandVoicesForOrgOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["brandVoicesForOrg"],
    queryFn: getBrandVoicesForOrg,
  });
};
