import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";
import { BrandVoice } from "../types";

export const createBrandVoice = async (
  brandVoice: Omit<BrandVoice, "id" | "createdBy" | "createdDt" | "updatedDt">
): Promise<BrandVoice> => {
  return axios.post("/createBrandVoice", brandVoice);
};

type UseCreateBrandVoiceOptions = {
  config?: MutationConfig<typeof createBrandVoice>;
};

export const useCreateBrandVoice = ({
  config,
}: UseCreateBrandVoiceOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: createBrandVoice,
  });
};
