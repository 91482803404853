import { PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import { StyleGuide } from "../../types";
import { StyleGuideListItem } from "../StyleGuideListItem";

interface StyleGuideListProps {
  styleGuides: StyleGuide[];
  onSelectStyleGuide: (styleGuide: StyleGuide) => void;
  selectedStyleGuideId: number | null;
  onDeleteStyleGuide: (styleGuide: StyleGuide) => void;
  onCreateNewStyleGuide: () => void;
}

export const StyleGuideList: React.FC<StyleGuideListProps> = ({
  styleGuides,
  onSelectStyleGuide,
  selectedStyleGuideId,
  onDeleteStyleGuide,
  onCreateNewStyleGuide,
}) => {
  return (
    <div className="space-y-0">
      <div
        className="p-3 cursor-pointer transition-colors flex items-center border-b border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-900 dark:text-gray-100"
        onClick={onCreateNewStyleGuide}
      >
        <PlusIcon className="h-5 w-5 mr-2 text-emerald-600 dark:text-emerald-400" />
        <span className="font-medium text-sm">Create New Style Guide</span>
      </div>
      {styleGuides.map((styleGuide) => (
        <StyleGuideListItem
          key={styleGuide.id}
          styleGuide={styleGuide}
          onSelect={() => onSelectStyleGuide(styleGuide)}
          isSelected={selectedStyleGuideId === styleGuide.id}
          deleteStyleGuide={() => onDeleteStyleGuide(styleGuide)}
        />
      ))}
    </div>
  );
};
