import { axios } from "@/lib/axios";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useMutation } from "react-query";
import { BrandVoice } from "../types";

export type UpdateBrandVoiceFields = Partial<
  Omit<BrandVoice, "id" | "orgId" | "createdBy" | "createdDt" | "updatedDt">
>;

export const updateBrandVoice = async ({
  id,
  updates,
}: {
  id: number;
  updates: UpdateBrandVoiceFields;
}): Promise<BrandVoice> => {
  return axios.post("/updateBrandVoice", { id, ...updates });
};

type UseUpdateBrandVoiceOptions = {
  config?: MutationConfig<typeof updateBrandVoice>;
};

export const useUpdateBrandVoice = ({
  config,
}: UseUpdateBrandVoiceOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: updateBrandVoice,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["brandVoicesForOrg"]);
      queryClient.invalidateQueries(["brandVoice", data.id]);
    },
  });
};
