import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Elements";
import { useNotificationStore } from "@/stores/notifications";
import React, { useState } from "react";
import { TbPlus } from "react-icons/tb";
import { useCreateTerm } from "../../api/createTerm";
import { PartOfSpeech, Term, TermRule } from "../../types";
import { TermForm } from "../TermForm";

type TermFormValues = {
  term: string;
  termRule: TermRule;
  partOfSpeech: PartOfSpeech | null;
  description: string;
  caseSensitive: boolean;
  examples: { acceptable: boolean; exampleText: string }[];
  mistakes: {
    mistakeText: string;
    partOfSpeech: PartOfSpeech;
    caseSensitive: boolean;
  }[];
};

interface CreateTermDialogProps {
  termLimit: number;
  currentTermCount: number;
  onLimitReached: () => void;
  checkForDuplicate: (termToCheck: Partial<Term>) => boolean;
}

export const CreateTermDialog: React.FC<CreateTermDialogProps> = ({
  termLimit,
  currentTermCount,
  onLimitReached,
  checkForDuplicate,
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotificationStore();

  const createTermMutation = useCreateTerm();

  const handleCreateTerm = async (formValues: TermFormValues) => {
    try {
      setIsLoading(true);
      if (checkForDuplicate(formValues)) {
        addNotification({
          type: "error",
          title: "Duplicate term",
          message:
            "A term with the same name and part of speech already exists.",
        });
        setIsLoading(false);
        return;
      }
      // Convert TermFormValues to Term
      const newTerm: Omit<Term, "id" | "hash"> = {
        ...formValues,
        primary: false,
        tags: [], // You may want to add tag functionality later
      };
      await createTermMutation.mutateAsync(newTerm);
      handleClose();
      addNotification({
        type: "success",
        title: "Term created",
        message: "The new term has been successfully created.",
      });
    } catch (error) {
      console.error("Failed to create term:", error);
      addNotification({
        type: "error",
        title: "Failed to create term",
        message: "An error occurred while creating the term.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(newOpen) => {
        // Only allow opening if under limit
        if (newOpen && currentTermCount >= termLimit) {
          onLimitReached();
          return;
        }
        setOpen(newOpen);
      }}
    >
      <DialogTrigger asChild>
        <Button
          className="w-full flex-nowrap whitespace-nowrap px-[9.5px]"
          textClassName="w-full text-left ml-[3px]"
          variant="primaryBlur"
          size="2xs"
          startIcon={
            <TbPlus
              strokeWidth={2.5}
              style={{
                height: "1rem",
                width: "1rem",
              }}
            />
          }
        >
          New Term
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Create New Term</DialogTitle>
        </DialogHeader>
        <DialogDescription className="space-y-4 px-4">
          <TermForm onSubmit={handleCreateTerm} isLoading={isLoading} />
        </DialogDescription>
        <DialogFooter className="px-4 pb-4">
          <div className="flex justify-end space-x-2">
            <Button variant="outlineBlur" onClick={handleClose} type="button">
              Cancel
            </Button>
            <Button
              type="submit"
              form="term-form"
              disabled={isLoading}
              isLoading={isLoading}
            >
              {isLoading ? "Creating..." : "Create Term"}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateTermDialog;
