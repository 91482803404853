import { Filter } from "@/components/Elements/TableFilter";
import { SelectCell } from "@/components/Elements/TemplateTable/SelectCell";
import { useCreateHubFolder } from "@/features/knowledge-hub/api/createHubFolder";
import { useCreateHubTag } from "@/features/knowledge-hub/api/createHubTag";
import {
  UpdateHubDocumentFields,
  useUpdateHubDocument,
} from "@/features/knowledge-hub/api/updateHubDocument";
import { HubDocument } from "@/features/knowledge-hub/types";
import { useNotificationStore } from "@/stores/notifications";
import { DocumentIcon } from "@heroicons/react/24/solid";
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { FolderIcon, Plus, TagIcon } from "lucide-react";
import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { HubFolderCell } from "../../HubFolderCell";
import { HubTagsCell } from "../../HubTagsCell";
import { HubVisibilityCell } from "../../HubVisibilityCell";
import { HubDocumentsTableProps } from "../types";

export const useHubDocumentsTable = (props: HubDocumentsTableProps) => {
  const { documents = [], folders = [], tags = [] } = props;
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationStore();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<Filter[]>([]);
  const [hubDocumentsToTag, setHubDocumentsToTag] = useState<HubDocument[]>([]);

  const createFolderMutation = useCreateHubFolder();
  const updateHubDocumentMutation = useUpdateHubDocument();
  const createTagMutation = useCreateHubTag();

  const filteredDocuments = useMemo(() => {
    return documents.filter((doc) => {
      return selectedFilters.every((filter) => {
        if (filter.parent === "tags") {
          return doc.tags?.some((tag) => tag.id === parseInt(filter.value));
        }

        return doc[filter.parent as keyof HubDocument]
          ?.toString()
          .toLowerCase()
          .includes(filter.value.toString().toLowerCase());
      });
    });
  }, [documents, selectedFilters]);

  const columns: ColumnDef<HubDocument>[] = [
    {
      id: "select",
      meta: { label: "Select" },
      header: SelectCell.Header,
      cell: SelectCell.Cell,
      enableSorting: false,
      enableHiding: false,
      size: 0,
    },
    {
      accessorKey: "name",
      meta: { label: "Name" },
      header: () => {
        return <p className="text-xs">Name</p>;
      },
      cell: ({ row }) => {
        return (
          <p className="text-xs font-medium dark:text-white">
            {row.original.name}
          </p>
        );
      },
      size: 500,
    },
    {
      accessorKey: "folderId",
      meta: { label: "Folder" },
      header: () => {
        return <p className="ml-2 text-xs">Folder</p>;
      },
      cell: ({ row }) => (
        <HubFolderCell document={row.original} folders={processedFolders} />
      ),
      size: 180,
    },
    {
      accessorKey: "updatedDt",
      meta: {
        label: "Edited",
      },
      header: () => <p className="text-xs">Edited</p>,
      cell: ({ row }) => {
        return (
          <p className="text-2xs font-normal text-zinc-600 dark:text-zinc-400">
            {dayjs().to(dayjs(row.getValue("updatedDt")))}
          </p>
        );
      },
      size: 130,
    },
    {
      accessorKey: "visibility",
      meta: {
        label: "Visibility",
      },
      header: () => <p className="text-xs">Visibility</p>,
      cell: ({ row }) => (
        <HubVisibilityCell
          document={row.original}
          onFieldUpdate={onFieldUpdate}
        />
      ),
      size: 100,
    },
    {
      accessorKey: "tags",
      meta: {
        label: "Tags",
      },
      header: () => <p className="text-xs">Tags</p>,
      cell: ({ row }) => (
        <HubTagsCell
          document={row.original}
          // @ts-ignore
          tags={processedTags}
          onFieldUpdate={onFieldUpdate}
        />
      ),
      size: 400,
    },
  ];

  const handleCreateTag = async (
    tagName: string,
    color: string,
    data: HubDocument | HubDocument[]
  ) => {
    setIsCreatingTag(true);
    try {
      const tagResponse = await createTagMutation.mutateAsync({
        name: tagName,
        color,
      });
      if (Array.isArray(data)) {
        data.forEach((doc) => {
          const existingTags = doc.tags?.map((tag) => tag.id) || [];
          onFieldUpdate(
            [{ field: "tags", value: [...existingTags, tagResponse.id] }],
            doc
          );
        });
      } else {
        const existingTags = data.tags?.map((tag) => tag.id) || [];
        onFieldUpdate(
          [{ field: "tags", value: [...existingTags, tagResponse.id] }],
          data
        );
      }

      await queryClient.invalidateQueries(["hubTags"]);

      addNotification({
        type: "success",
        title: "Tag created",
        message: `Tag "${tagName}" has been created successfully.`,
      });
    } catch (error) {
      console.error("Error creating tag:", error);
      addNotification({
        type: "error",
        title: "Failed to create tag",
        message: "An error occurred while creating the tag.",
      });
    } finally {
      setIsCreatingTag(false);
    }
  };

  const handleCreateFolder = async (
    folderName: string,
    data: HubDocument | HubDocument[]
  ) => {
    setIsCreatingFolder(true);
    try {
      const folderResponse = await createFolderMutation.mutateAsync({
        name: folderName,
      });
      onFieldUpdate([{ field: "folderId", value: folderResponse.id }], data);

      await queryClient.invalidateQueries(["hubFoldersForOrg"]);

      addNotification({
        type: "success",
        title: "Folder created",
        message: `Folder "${folderName}" has been created successfully.`,
      });
    } catch (error) {
      console.error("Error creating folder:", error);
      addNotification({
        type: "error",
        title: "Failed to create folder",
        message: "An error occurred while creating the folder.",
      });
    } finally {
      setIsCreatingFolder(false);
    }
  };

  const processedTags = useMemo(() => {
    const tagOptions = tags.map(
      (tag) => ({
        label: tag.name,
        value: tag.id.toString(),
        color: tag.color,
        onClick: (
          _value: any,
          selectedRowsOrDocument: { original: HubDocument }[] | HubDocument
        ) => {
          if (Array.isArray(selectedRowsOrDocument)) {
            selectedRowsOrDocument.forEach((row) => {
              const existingTags =
                row.original.tags?.map((tag) => tag.id) || [];
              onFieldUpdate(
                [{ field: "tags", value: [...existingTags, tag.id] }],
                row.original
              );
            });
          } else {
            const existingTags =
              selectedRowsOrDocument.tags?.map((tag) => tag.id) || [];
            onFieldUpdate(
              [{ field: "tags", value: [...existingTags, tag.id] }],
              selectedRowsOrDocument
            );
          }
        },
        icon: <TagIcon className="w-3 h-3 mr-2" />,
        type: "button",
      }),
      [tags]
    );

    const newTagOption = {
      value: "New Tag",
      label: "New Tag",
      isLoading: isCreatingTag,
      onClick: (
        _value: any,
        selectedRowsOrDocument: { original: HubDocument }[] | HubDocument
      ) => {
        const docs = Array.isArray(selectedRowsOrDocument)
          ? selectedRowsOrDocument.map((row) => row.original)
          : [selectedRowsOrDocument];
        setHubDocumentsToTag(docs);
      },
      icon: <Plus className="w-3 h-3 mr-2" />,
      type: "button",
    };

    return [newTagOption, ...tagOptions];
  }, [tags, isCreatingTag]);

  const processedFolders = useMemo(() => {
    const folderOptions = folders.map((folder) => ({
      label: folder.name,
      value: folder.id.toString(),
      onClick: (
        _value: any,
        selectedRowsOrDocument: { original: HubDocument }[] | HubDocument
      ) => {
        onFieldUpdate(
          [{ field: "folderId", value: folder.id }],
          Array.isArray(selectedRowsOrDocument)
            ? selectedRowsOrDocument.map((row) => row.original)
            : selectedRowsOrDocument
        );
      },
      icon: <FolderIcon className="w-3 h-3 mr-2" />,
    }));

    const newFolderOption = {
      value: "New Folder",
      label: "New Folder",
      isLoading: isCreatingFolder,
      onClick: (
        folderName: string,
        selectedRowsOrDocument: { original: HubDocument }[] | HubDocument
      ) =>
        handleCreateFolder(
          folderName,
          Array.isArray(selectedRowsOrDocument)
            ? selectedRowsOrDocument.map((row) => row.original)
            : selectedRowsOrDocument
        ),
      icon: <Plus className="w-3 h-3 mr-2" />,
      type: "input",
    };

    return [newFolderOption, ...folderOptions];
  }, [folders, isCreatingFolder]);

  const table = useReactTable({
    data: filteredDocuments,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const onFieldUpdate = (
    updates: { field: keyof UpdateHubDocumentFields; value: any }[],
    data: HubDocument | HubDocument[]
  ) => {
    const optimisticUpdate = (
      doc: HubDocument,
      updates: { field: keyof UpdateHubDocumentFields; value: any }[]
    ) => {
      return updates.reduce(
        (acc, update) => ({ ...acc, [update.field]: update.value }),
        doc
      );
    };

    const updateDocumentField = async (
      doc: HubDocument,
      updatesArray: { field: keyof UpdateHubDocumentFields; value: any }[]
    ) => {
      try {
        const updates = updatesArray.reduce(
          (acc, update) => ({ ...acc, [update.field]: update.value }),
          {}
        );
        await updateHubDocumentMutation.mutateAsync({
          id: doc.id,
          updates,
        });
        await Promise.all([
          queryClient.invalidateQueries(["hubDocuments"]),
          queryClient.invalidateQueries(["hubDocument", doc.hash]),
        ]);
      } catch (error) {
        console.error("Error updating document:", error);
        addNotification({
          type: "error",
          title: "Document update failed",
          message: `We encountered an issue while updating the document "${doc.name}". Please try again.`,
        });
      }
    };

    const handleUpdate = async (doc: HubDocument) => {
      /*const optimisticDoc = optimisticUpdate(doc, updates);
      setProcessedDocuments((currentDocs) =>
        currentDocs.map((currentDoc) =>
          currentDoc.id === doc.id ? optimisticDoc : currentDoc
        )
      );*/
      await updateDocumentField(doc, updates);
    };

    if (Array.isArray(data)) {
      // Optimistically update the state for all selected documents
      data.forEach((doc) => {
        handleUpdate(doc);
      });

      // Immediately deselect all rows after optimistic update
      table.toggleAllPageRowsSelected(false);
    } else {
      handleUpdate(data);
      // Immediately deselect the row after optimistic update
      table.toggleAllPageRowsSelected(false);
    }
  };

  const filters = useMemo<Filter[]>(
    () => [
      {
        value: "name",
        label: "Name",
        icon: <DocumentIcon />,
        parent: "name",
        type: "input",
      },
      {
        label: "Folder",
        value: "folderId",
        children: folders.map((folder) => ({
          label: folder.name,
          value: folder.id.toString(),
          icon: <FolderIcon />,
          parent: "folderId",
          type: "filter",
        })),
        icon: <FolderIcon />,
        type: "filter",
      },
      {
        label: "Tag",
        type: "filter",
        value: "tags",
        children: tags.map((tag) => ({
          label: tag.name,
          value: tag.id.toString(),
          icon: <TagIcon />,
          parent: "tags",
          type: "filter",
        })),
        icon: <TagIcon />,
      },
    ],
    [folders]
  );

  const removeFilter = (idx: number) => {
    const newFilters = [...selectedFilters];
    newFilters.splice(idx, 1);
    setSelectedFilters(newFilters);
  };

  const closeCreateHubTagDialog = () => {
    setHubDocumentsToTag([]);
  };

  const finalizeCreateHubTag = async (tagName: string, color: string) => {
    await handleCreateTag(tagName, color, hubDocumentsToTag);
    closeCreateHubTagDialog();
  };

  const isCreateHubTagDialogOpen = hubDocumentsToTag.length > 0;

  return {
    filters,
    table,
    processedFolders,
    processedTags,
    selectedFilters,
    removeFilter,
    setSelectedFilters,
    isCreateHubTagDialogOpen,
    finalizeCreateHubTag,
    closeCreateHubTagDialog,
  };
};
