import { Combobox } from "@/components/Elements";
import { cn } from "@/utils/style";
import { PlusIcon } from "@heroicons/react/24/outline";
import { HubDocument } from "../../types";

export const FolderTag = ({ children }: { children: string }) => {
  return (
    <span className="inline-flex items-center rounded-full px-2 py-1 text-2xs text-zinc-600 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-white w-32">
      <span className="truncate">{children}</span>
    </span>
  );
};

export const AddFolderTag = () => {
  return (
    <span className="inline-flex items-center rounded-full px-2 py-1 text-2xs text-zinc-400 dark:text-zinc-600 hover:text-zinc-900 dark:hover:text-white">
      <PlusIcon className="mr-2 h-3 w-3" />
      Folder
    </span>
  );
};

export const HubFolderCell = ({
  document,
  folders,
}: {
  document: HubDocument;
  folders: (
    | {
        value: string;
        label: string;
        isLoading: boolean;
        onClick: (_value: string, data: HubDocument) => Promise<void>;
        icon: JSX.Element;
        type: string;
      }
    | {
        label: string;
        value: string;
        onClick: (_value: any, data: HubDocument) => void;
        icon: JSX.Element;
      }
  )[];
}) => {
  const folderName =
    folders.find((folder) => folder?.value === document?.folderId?.toString())
      ?.label || null;

  return (
    <Combobox
      data={document}
      options={folders}
      buttonVariant="buttonNode"
      buttonClassName="rounded-full"
      value={document.folderId?.toString() || ""}
      showEndIcon={false}
      enableSearch
      searchPlaceholder="folders"
      className={cn("h-7", folderName && "")}
      trigger={
        folderName ? <FolderTag>{folderName}</FolderTag> : <AddFolderTag />
      }
    />
  );
};
