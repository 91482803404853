import { Button, Input } from "@/components/Elements";
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements/Dialog";
import React from "react";
import { LevelBadge } from "../BrandVoiceListItem";

interface BrandVoicePreviewDialogProps {
  show: boolean;
  onHide: () => void;
  preview: string | null;
  formalityLevel: number;
  politenessLevel: number;
  emotionLevel: number;
  assertivenessLevel: number;
  context: string;
  setContext: (context: string) => void;
  onGeneratePreview: () => void;
  isGeneratingPreview: boolean;
}

export const BrandVoicePreviewDialog: React.FC<
  BrandVoicePreviewDialogProps
> = ({
  show,
  onHide,
  preview,
  formalityLevel,
  politenessLevel,
  emotionLevel,
  assertivenessLevel,
  context,
  setContext,
  onGeneratePreview,
  isGeneratingPreview,
}) => {
  return (
    <Dialog open={show} onOpenChange={onHide}>
      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader className="pb-0 flex items-center justify-between">
          <DialogTitle>Brand Voice Preview</DialogTitle>
          <DialogCloseButton close={onHide} />
        </DialogHeader>
        <DialogDescription className="px-4 pb-4">
          <div className="space-y-4">
            <div>
              <label
                htmlFor="context"
                className="block text-sm font-medium mb-1 text-zinc-700 dark:text-zinc-300"
              >
                Preview Instructions
              </label>
              <Input
                id="context"
                placeholder="Advertisement, blog post, email, etc."
                value={context}
                onChange={(value) => setContext(value)}
                className="w-full"
              />
            </div>
            {preview && (
              <>
                <div className="flex justify-center gap-4 mb-3 px-2">
                  <LevelBadge level={formalityLevel} label="Formality" />
                  <LevelBadge level={politenessLevel} label="Politeness" />
                  <LevelBadge level={emotionLevel} label="Emotion" />
                  <LevelBadge
                    level={assertivenessLevel}
                    label="Assertiveness"
                  />
                </div>

                <div className="mt-4">
                  <h3 className="text-lg font-semibold mb-2 text-zinc-800 dark:text-zinc-200">
                    Preview:
                  </h3>
                  <p className="text-sm text-zinc-600 dark:text-zinc-400">
                    {preview}
                  </p>
                </div>
              </>
            )}
          </div>
        </DialogDescription>
        <DialogFooter className="px-4 py-6 border-t dark:border-t-zinc-800 w-full">
          <Button variant="outlineBlur" onClick={onHide}>
            Cancel
          </Button>
          <Button
            onClick={onGeneratePreview}
            isLoading={isGeneratingPreview}
            variant="primaryBlur"
          >
            Generate Preview
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
