import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Elements";
import { Button } from "@/components/Elements/Button";
import React from "react";
import { TbAlertTriangle } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { TERM_LIMITS } from "../../hook";

interface UpgradeToContinueDialogProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  currentTermCount: number;
  termLimit: number;
}

const UpgradeToContinueDialog: React.FC<UpgradeToContinueDialogProps> = ({
  open,
  onClose,
  onCancel,
  currentTermCount,
  termLimit,
}) => {
  const navigate = useNavigate();

  return (
    <Dialog open={open}>
      <DialogContent className="sm:max-w-[525px] p-0 bg-white dark:bg-zinc-900 border border-zinc-200 dark:border-zinc-700">
        <DialogHeader className="px-6 pt-6 pb-0 flex items-center justify-between">
          <DialogTitle className="text-xl font-semibold text-zinc-900 dark:text-zinc-100 flex items-center">
            <TbAlertTriangle className="w-5 h-5 mr-2 text-yellow-500" />
            Unlock More Frase Terms Power
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="px-6 pt-4 space-y-4">
          <p className="text-sm text-zinc-600 dark:text-zinc-400">
            You've reached your term limit ({currentTermCount}/{termLimit}).
            Upgrade your plan to add more terms and ensure consistent, on-brand
            content across every piece you create.
          </p>
          <p className="text-sm text-zinc-600 dark:text-zinc-400">
            With Frase Terms, you can:
          </p>
          <ul className="list-disc pl-5 text-sm text-zinc-600 dark:text-zinc-400">
            <li>
              Create a unified voice by enforcing approved terminology across
              teams.
            </li>
            <li>
              Avoid mistakes by flagging disallowed terms during the writing
              process.
            </li>
            <li>
              Strengthen brand consistency with clear guidelines for content
              creators.
            </li>
          </ul>
          <p className="text-sm text-zinc-600 dark:text-zinc-400">
            Empower your team with more terms to create polished, professional
            content every time.
          </p>
        </DialogDescription>
        <div className="px-6 py-4">
          <Table className="w-full mb-4">
            <TableHeader className="bg-zinc-50 dark:bg-zinc-800">
              <TableRow>
                <TableHead className="w-1/2 py-1 px-4 text-left text-xs font-semibold text-zinc-900 dark:text-white">
                  Plan
                </TableHead>
                <TableHead className="w-1/2 py-1 px-4 text-right text-xs font-semibold text-zinc-900 dark:text-white">
                  Documents
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                  Free
                </TableCell>
                <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                  {TERM_LIMITS.free}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                  Basic
                </TableCell>
                <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                  {TERM_LIMITS.basic}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                  Team
                </TableCell>
                <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                  {TERM_LIMITS.team}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                  Enterprise
                </TableCell>
                <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                  Unlimited
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <DialogFooter className="px-6 pb-6 flex items-center justify-end space-x-2">
          <Button
            type="button"
            onClick={() => {
              onClose();
              onCancel();
            }}
            variant="outlineBlur"
            className="text-zinc-600 dark:text-zinc-400 border-zinc-300 dark:border-zinc-600 hover:bg-zinc-100 dark:hover:bg-zinc-800"
          >
            Cancel
          </Button>
          {termLimit === 100 ? (
            <Button
              type="button"
              variant="primaryBlur"
              className="bg-blue-600 text-white hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600"
              onClick={() => (window.location.href = "mailto:team@frase.io")}
            >
              Contact Us
            </Button>
          ) : (
            <Button
              type="button"
              variant="primaryBlur"
              className="bg-emerald-600 text-white hover:bg-emerald-700 dark:bg-emerald-500 dark:hover:bg-emerald-600"
              onClick={() => navigate("/app/settings/plans")}
            >
              Upgrade Plan
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradeToContinueDialog;
