import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
  RadioGroup,
} from "@/components/Elements";
import { useNotificationStore } from "@/stores/notifications";
import { cn } from "@/utils/style";
import { useState } from "react";
import { TbFileText, TbFileUpload, TbPlus } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useCreateHubDocument } from "../../api/createHubDocument";
import { DocumentParser } from "../../utils/documentParser";

interface NewHubDocumentDialogProps {
  documentLimit: number;
  currentDocumentCount: number;
  onLimitReached: () => void;
}

export const NewHubDocumentDialog = ({
  documentLimit,
  currentDocumentCount,
  onLimitReached,
}: NewHubDocumentDialogProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [documentType, setDocumentType] = useState<"blank" | "upload" | null>(
    null
  );
  const [documentTitle, setDocumentTitle] = useState("");
  const [visibility, setVisibility] = useState<"private" | "team">("private");

  const { addNotification } = useNotificationStore();
  const createHubDocumentMutation = useCreateHubDocument();
  const navigate = useNavigate();

  const handleCreateBlankDocument = async () => {
    setIsSubmitting(true);
    try {
      const newDocument = await createHubDocumentMutation.mutateAsync({
        name: documentTitle.trim() || "Untitled",
        content: "",
        visibility,
      });
      setOpen(false);
      // Navigate to the new document's page
      navigate(`/app/knowledge-hub/${newDocument.hash}`);
    } catch (error) {
      console.error("Failed to create document:", error);
      addNotification({
        title: "Failed to create document",
        message: "Please try again later",
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUploadDocument = async (file: File) => {
    setIsSubmitting(true);
    try {
      const documentParser = new DocumentParser();
      const content = await documentParser.parseDocument(file);
      const newDocument = await createHubDocumentMutation.mutateAsync({
        name: documentTitle.trim() || file.name,
        content,
        visibility,
      });
      setOpen(false);
      // Navigate to the new document's page
      navigate(`/app/knowledge-hub/${newDocument.hash}`);
    } catch (error) {
      addNotification({
        title: "Failed to upload document",
        message: "Please try again later",
        type: "error",
      });
      console.error("Failed to upload document:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClick = () => {
    if (currentDocumentCount >= documentLimit) {
      onLimitReached();
      return;
    }
    setOpen(true);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(newOpen) => {
        // Only allow opening if under limit
        if (newOpen && currentDocumentCount >= documentLimit) {
          onLimitReached();
          return;
        }
        setOpen(newOpen);
      }}
    >
      <DialogTrigger asChild>
        <Button
          className="w-full flex-nowrap whitespace-nowrap px-[9.5px]"
          textClassName="w-full text-left ml-[3px]"
          variant="primaryBlur"
          size="2xs"
          startIcon={
            <TbPlus
              strokeWidth={2.5}
              style={{
                height: "1rem",
                width: "1rem",
              }}
            />
          }
          onClick={handleClick}
        >
          New knowledge document
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create a New Knowledge Hub Document</DialogTitle>
        </DialogHeader>
        <DialogDescription className="px-4 overflow-hidden">
          <div className="mb-4">
            <Input
              type="text"
              placeholder="Enter document title"
              value={documentTitle}
              onChange={(value) => setDocumentTitle(value)}
              className="w-full"
            />
          </div>
          <RadioGroup className="flex-col w-full">
            <div
              onClick={() => setDocumentType("blank")}
              className={cn(
                "transition-all w-full flex items-center justify-between space-x-3 space-y-0 p-4 rounded-md text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none dark:bg-zinc-900",
                documentType === "blank"
                  ? "ring-emerald-600 ring-2 dark:ring-emerald-600"
                  : "bg-white"
              )}
            >
              <div className="flex flex-row items-center space-x-2">
                <div className="w-12 h-12 min-w-12 min-h-12 bg-zinc-100 dark:bg-zinc-800 rounded-md flex items-center justify-center border border-zinc-200 dark:border-zinc-700 shrink-0">
                  <TbFileText className="w-6 h-6" />
                </div>
                <div className="flex flex-col flex-wrap">
                  <span className="font-bold ml-2">Blank Document</span>
                  <span className="text-sm text-zinc-500 ml-2">
                    Create a new empty document
                  </span>
                </div>
              </div>
            </div>
            <div
              onClick={() => setDocumentType("upload")}
              className={cn(
                "transition-all w-full flex items-center justify-between space-x-3 space-y-0 p-4 rounded-md text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none dark:bg-zinc-900",
                documentType === "upload"
                  ? "ring-emerald-600 ring-2 dark:ring-emerald-600"
                  : "bg-white"
              )}
            >
              <div className="flex flex-row items-center space-x-2">
                <div className="w-12 h-12 min-w-12 min-h-12 bg-zinc-100 dark:bg-zinc-800 rounded-md flex items-center justify-center border border-zinc-200 dark:border-zinc-700 shrink-0">
                  <TbFileUpload className="w-6 h-6" />
                </div>
                <div className="flex flex-col flex-wrap">
                  <span className="font-bold ml-2">Upload Document</span>
                  <span className="text-sm text-zinc-500 ml-2">
                    Upload an existing document
                  </span>
                  <span className="text-xs text-zinc-400 ml-2">
                    Supported formats: CSV, TXT, PDF, DOCX
                  </span>
                </div>
              </div>
            </div>
          </RadioGroup>

          {documentType && (
            <div className="mt-4">
              <RadioGroup className="flex-col w-full space-y-2">
                <div
                  onClick={() => setVisibility("private")}
                  className={cn(
                    "transition-all w-full flex items-center justify-between p-3 rounded-md text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none dark:bg-zinc-900"
                  )}
                >
                  <div className="flex items-center space-x-3">
                    <input
                      type="radio"
                      name="visibility"
                      value="private"
                      checked={visibility === "private"}
                      onChange={() => setVisibility("private")}
                      className="h-4 w-4 text-emerald-600 border-zinc-300 focus:ring-emerald-600"
                    />
                    <div>
                      <p className="font-medium">Private</p>
                      <p className="text-sm text-zinc-500">
                        Only you can access this document
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => setVisibility("team")}
                  className={cn(
                    "transition-all w-full flex items-center justify-between p-3 rounded-md text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none dark:bg-zinc-900"
                  )}
                >
                  <div className="flex items-center space-x-3">
                    <input
                      type="radio"
                      name="visibility"
                      value="public"
                      checked={visibility === "team"}
                      onChange={() => setVisibility("team")}
                      className="h-4 w-4 text-emerald-600 border-zinc-300 focus:ring-emerald-600"
                    />
                    <div>
                      <p className="font-medium">Team</p>
                      <p className="text-sm text-zinc-500">
                        Visible to everyone in your team
                      </p>
                    </div>
                  </div>
                </div>
              </RadioGroup>
            </div>
          )}
        </DialogDescription>
        <DialogFooter className="flex justify-between p-4 pt-0">
          <Button
            variant="outlineBlur"
            onClick={() => {
              setDocumentType(null);
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primaryBlur"
            onClick={() => {
              if (documentType === "blank") {
                handleCreateBlankDocument();
              } else if (documentType === "upload") {
                // Open file picker
                const input = document.createElement("input");
                input.type = "file";
                input.accept = ".csv,.txt,.pdf,.docx";
                input.onchange = (e) => {
                  const file = (e.target as HTMLInputElement).files?.[0];
                  if (file) {
                    handleUploadDocument(file);
                  }
                };
                input.click();
              }
            }}
            isLoading={isSubmitting}
            disabled={!documentType || isSubmitting}
          >
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default NewHubDocumentDialog;
