import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
  Textarea,
} from "@/components/Elements";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { PartOfSpeech, Term, TermRule } from "../../types";
import { TermExampleField } from "../TermExampleField";
import { TermMistakeField } from "../TermMistakeField";

const termSchema = z.object({
  term: z.string().min(1, "Term is required"),
  termRule: z.nativeEnum(TermRule),
  partOfSpeech: z.nativeEnum(PartOfSpeech).nullable(),
  description: z.string(),
  caseSensitive: z.boolean(),
  examples: z.array(
    z.object({
      acceptable: z.boolean(),
      exampleText: z
        .string()
        .min(1, "Example text is required")
        .max(100, "Example text must be 100 characters or less"),
    })
  ),
  mistakes: z.array(
    z.object({
      mistakeText: z
        .string()
        .min(1, "Mistake text is required")
        .max(100, "Mistake text must be 100 characters or less"),
      partOfSpeech: z.nativeEnum(PartOfSpeech),
      caseSensitive: z.boolean(),
    })
  ),
});

type TermFormValues = z.infer<typeof termSchema>;

interface TermFormProps {
  initialData?: Term;
  onSubmit: (data: TermFormValues) => void;
  isLoading?: boolean;
}

export const TermForm: React.FC<TermFormProps> = ({
  initialData,
  onSubmit,
  isLoading = false,
}) => {
  const form = useForm<TermFormValues>({
    resolver: zodResolver(termSchema),
    defaultValues: initialData || {
      term: "",
      termRule: TermRule.PENDING,
      partOfSpeech: null,
      description: "",
      caseSensitive: false,
      examples: [],
      mistakes: [],
    },
  });

  const handleSubmit = (values: TermFormValues) => {
    onSubmit(values);
  };

  const formatTermRule = (rule: string) => {
    return rule
      .split("_")
      .map((word) => {
        if (word.toLowerCase() === "dont") {
          return "Don't";
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="grid grid-cols-2 gap-x-6"
        id="term-form"
      >
        <div className="space-y-4">
          <FormField
            control={form.control}
            name="term"
            rules={{
              maxLength: {
                value: 45,
                message: "Term must be 45 characters or less",
              },
            }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Term</FormLabel>
                <FormControl>
                  <Input {...field} disabled={isLoading} maxLength={45} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            rules={{
              maxLength: {
                value: 120,
                message: "Description must be 120 characters or less",
              },
            }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    maxLength={120}
                    rows={2}
                    disabled={isLoading}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="termRule"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Term Rule</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  disabled={isLoading}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a term rule" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.values(TermRule).map((rule) => (
                      <SelectItem key={rule} value={rule}>
                        {formatTermRule(rule)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="caseSensitive"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">Case Sensitive</FormLabel>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    disabled={isLoading}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <div className="space-y-4 max-h-[calc(600px)] overflow-y-auto pr-4">
          <FormField
            control={form.control}
            name="partOfSpeech"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Part of Speech</FormLabel>
                <Select
                  onValueChange={(value) => {
                    if (value === "NULL") {
                      field.onChange(null);
                    } else {
                      field.onChange(value);
                    }
                  }}
                  defaultValue={field.value || "NULL"}
                  disabled={isLoading}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a part of speech" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="NULL">N/A</SelectItem>
                    {Object.values(PartOfSpeech).map((pos) => (
                      <SelectItem key={pos} value={pos}>
                        {pos}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <TermExampleField
            control={form.control}
            name="examples"
            disabled={isLoading}
          />

          <TermMistakeField
            control={form.control}
            name="mistakes"
            disabled={isLoading}
          />
        </div>
      </form>
    </Form>
  );
};
