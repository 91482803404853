import { useMemo } from "react";
import { useSubscription } from "../auth/api/getSubscription";
import { useGetHubDocuments } from "./api/getHubDocuments";

export const DOCUMENT_LIMITS = {
  free: 1,
  solo: 1,
  basic: 3,
  team: 15,
  enterprise: Infinity,
} as const;

const getDocumentLimit = (plan?: string, appsumo?: boolean): number => {
  if (appsumo) return DOCUMENT_LIMITS.team;
  if (!plan || plan.includes("solo")) return DOCUMENT_LIMITS.free;
  if (plan.includes("basic")) return DOCUMENT_LIMITS.basic;
  if (plan.includes("team")) return DOCUMENT_LIMITS.team;
  if (plan.includes("enterprise")) return DOCUMENT_LIMITS.enterprise;
  return DOCUMENT_LIMITS.free;
};

export const useKnowledgeHubDocuments = () => {
  const { data: subscription, isLoading: isLoadingSubscription } =
    useSubscription({});
  const { data: allDocuments, isLoading: isLoadingDocuments } =
    useGetHubDocuments();

  const documentLimit = getDocumentLimit(
    subscription?.plan,
    subscription?.appsumo ?? false
  );

  const currentDocumentCount = useMemo(
    () => allDocuments?.length || 0,
    [allDocuments]
  );

  const isLoading = useMemo(
    () => isLoadingSubscription || isLoadingDocuments,
    [isLoadingSubscription, isLoadingDocuments]
  );

  const overLimit = currentDocumentCount > documentLimit;

  const useableDocuments = useMemo(() => {
    if (overLimit) return allDocuments?.filter((doc) => doc.primary);
    return allDocuments;
  }, [overLimit, allDocuments, currentDocumentCount, documentLimit]);

  const needToSetPrimary = useMemo(() => {
    return overLimit && !useableDocuments?.length;
  }, [overLimit, useableDocuments]);

  return {
    documentLimit,
    currentDocumentCount,
    isLoading,
    allDocuments,
    useableDocuments,
    needToSetPrimary,
  };
};
