import { Button } from "@/components/Elements";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements/Tooltip";
import { cn } from "@/utils/style";
import { TrashIcon } from "@heroicons/react/24/outline";
import React from "react";
import { BrandVoice } from "../../types";

interface BrandVoiceListItemProps {
  brandVoice: BrandVoice;
  onSelect: () => void;
  isSelected: boolean;
  deleteBrandVoice: () => void;
}

const badgeColors: { [key: string]: string } = {
  Formality: "bg-blue-200 dark:bg-blue-700 text-blue-800 dark:text-blue-200",
  Politeness:
    "bg-amber-200 dark:bg-amber-700 text-amber-800 dark:text-amber-200",
  Emotion:
    "bg-purple-200 dark:bg-purple-700 text-purple-800 dark:text-purple-200",
  Assertiveness:
    "bg-orange-200 dark:bg-orange-700 text-orange-800 dark:text-orange-200",
};

export const LevelBadge: React.FC<{ level: number; label: string }> = ({
  level,
  label,
}) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger>
        <span
          className={cn(
            "inline-flex items-center justify-center w-auto h-6 px-2 text-xs font-medium rounded-full mr-1",
            badgeColors[label]
          )}
        >
          {label[0]}
          <span className="ml-1">{level}/5</span>
        </span>
      </TooltipTrigger>
      <TooltipContent>
        <p>
          {label}: {level}/5
        </p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export const BrandVoiceListItem: React.FC<BrandVoiceListItemProps> = ({
  brandVoice,
  onSelect,
  isSelected,
  deleteBrandVoice,
}) => {
  return (
    <div
      className={cn(
        "p-2 cursor-pointer transition-all border-b border-gray-200 dark:border-gray-700 hover:shadow-md relative",
        isSelected
          ? "bg-emerald-100 dark:bg-emerald-900 text-emerald-800 dark:text-emerald-100"
          : "hover:bg-gray-50 dark:hover:bg-gray-800 text-gray-900 dark:text-gray-100"
      )}
      onClick={onSelect}
    >
      <Button
        variant="buttonIcon"
        className="absolute top-2 right-2"
        buttonIcon={<TrashIcon className="h-4 w-4" />}
        tooltipContent="Delete brand voice"
        onClick={(e) => {
          e.stopPropagation();
          deleteBrandVoice();
        }}
      />
      <div className="pr-8">
        <h3 className="font-semibold text-base mb-2 truncate">
          {brandVoice.name}
        </h3>
        <p className="text-sm text-gray-600 dark:text-gray-400 mb-3 line-clamp-2">
          {brandVoice.description}
        </p>
      </div>
      <div className="flex justify-between items-center">
        <LevelBadge level={brandVoice.formalityLevel} label="Formality" />
        <LevelBadge level={brandVoice.politenessLevel} label="Politeness" />
        <LevelBadge level={brandVoice.emotionLevel} label="Emotion" />
        <LevelBadge
          level={brandVoice.assertivenessLevel}
          label="Assertiveness"
        />
      </div>
    </div>
  );
};
