import { axios } from "@/lib/axios";
import { queryClient } from "@/lib/react-query";
import { defaultTermTags, makeDefaultTerms } from "../constants";
import { TermTag } from "../types";

export const insertDefaultTermsIfNeverDoneBefore = async () => {
  const hasBeenDone = await axios.post("/isDefaultTermsInitialized");
  if (hasBeenDone) {
    return;
  }

  const insertedTermTags: TermTag[] = await axios.post(
    "/insertDefaultTermTags",
    defaultTermTags
  );

  const defaultTerms = makeDefaultTerms(insertedTermTags);

  await axios.post("/insertDefaultTerms", defaultTerms);
  queryClient.invalidateQueries(["terms"]);
  queryClient.invalidateQueries(["termTags"]);
};
