import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { useNotificationStore } from "@/stores/notifications";
import { DialogPortal } from "@radix-ui/react-dialog";
import React from "react";
import { useQueryClient } from "react-query";
import { useDeleteStyleGuide } from "../../api/deleteStyleGuide";
import { StyleGuide } from "../../types";

interface ConfirmDeleteStyleGuideDialogProps {
  styleGuide: StyleGuide;
  show: boolean;
  onHide: () => void;
  onSuccess?: () => void;
}

export const ConfirmDeleteStyleGuideDialog: React.FC<
  ConfirmDeleteStyleGuideDialogProps
> = ({ styleGuide, show, onHide, onSuccess }) => {
  const deleteStyleGuideMutation = useDeleteStyleGuide();
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  const handleDelete = async () => {
    try {
      await deleteStyleGuideMutation.mutateAsync({ id: styleGuide.id });
      await queryClient.invalidateQueries(["styleGuidesForOrg"]);
      addNotification({
        title: "Style Guide Deleted",
        message: "Your style guide has been deleted successfully.",
        type: "success",
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      addNotification({
        title: "Error",
        message: "Failed to delete style guide. Please try again.",
        type: "error",
      });
    }
  };

  return (
    <Dialog open={show} onOpenChange={onHide}>
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="pb-0 flex items-center justify-between">
            <DialogTitle>Delete Style Guide</DialogTitle>
          </DialogHeader>
          <DialogDescription className="px-4">
            Are you sure you want to delete the style guide "{styleGuide.name}"?
            This action cannot be undone.
          </DialogDescription>
          <DialogFooter className="px-4 pb-4">
            <Button variant="outlineBlur" onClick={onHide}>
              Cancel
            </Button>
            <Button
              variant="destructiveBlur"
              className="bg-red-600"
              onClick={handleDelete}
              isLoading={deleteStyleGuideMutation.isLoading}
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
