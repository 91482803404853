import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";

export const extractPdfText = async ({
  file,
}: {
  file: File;
}): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post("/extractPdfText", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

type UseExtractPdfTextOptions = {
  config?: MutationConfig<typeof extractPdfText>;
};

export const useExtractPdfText = ({
  config,
}: UseExtractPdfTextOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: extractPdfText,
  });
};
