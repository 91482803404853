import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/Elements";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React from "react";
import { ChoiceRuleDefinition, ChoiceStyleGuideRule } from "../../../types";

interface ChoiceRuleFieldProps {
  ruleDefinition: ChoiceRuleDefinition;
  styleGuideRule: ChoiceStyleGuideRule;
  onRuleChange: (updatedRule: ChoiceStyleGuideRule) => void;
}

export const ChoiceRuleField: React.FC<ChoiceRuleFieldProps> = ({
  ruleDefinition,
  styleGuideRule,
  onRuleChange,
}) => {
  const handleValueChange = (value: string) => {
    onRuleChange({ ...styleGuideRule, choiceValue: value });
  };

  const options = ruleDefinition.choiceOptions.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className="w-full justify-between"
          endIcon={<ChevronDownIcon className="h-4 w-4" />}
        >
          {options.find((option) => option.value === styleGuideRule.choiceValue)
            ?.label || "Select..."}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-full">
        {options.map((option) => (
          <DropdownMenuItem
            key={option.value}
            onSelect={() => handleValueChange(option.value)}
          >
            {option.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
