import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { TermTag } from "../types";

export const getTermTags = async (): Promise<TermTag[]> => {
  return axios.post("/getTermTags");
};

type QueryFnType = typeof getTermTags;

type UseGetTermTagsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useGetTermTags = ({ config }: UseGetTermTagsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["termTags"],
    queryFn: getTermTags,
  });
};
