import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";
import { HubTag } from "../types";

export const createHubTag = async ({
  name,
  color,
}: {
  name: string;
  color: string;
}): Promise<HubTag> => {
  return axios.post("/createHubTag", { name, color });
};

type UseCreateHubTagOptions = {
  config?: MutationConfig<typeof createHubTag>;
};

export const useCreateHubTag = ({ config }: UseCreateHubTagOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: createHubTag,
  });
};
