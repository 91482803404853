import { axios } from "@/lib/axios";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useMutation } from "react-query";
import { Term } from "../types";

export const createTerm = async (
  termData: Omit<Term, "id" | "hash">
): Promise<Term> => {
  return axios.post("/createTerm", termData);
};

type UseCreateTermOptions = {
  config?: MutationConfig<typeof createTerm>;
};

export const useCreateTerm = ({ config }: UseCreateTermOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: createTerm,
    onSuccess: () => {
      queryClient.invalidateQueries(["terms"]);
    },
  });
};
