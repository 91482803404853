import { useMemo } from "react";
import { useSubscription } from "../auth/api/getSubscription";
import { useGetRuleDefinitions } from "./api/getRuleDefinitions";
import { useGetStyleGuidesForOrg } from "./api/getStyleGuidesForOrg";
import { PlanAccessLevel } from "./types";

const getUserPlanLevel = (
  plan?: string,
  appsumo?: boolean
): PlanAccessLevel => {
  if (appsumo) return "team";
  if (!plan || plan.includes("solo")) return "free";
  if (plan.includes("basic")) return "basic";
  if (plan.includes("team")) return "team";
  if (plan.includes("enterprise")) return "enterprise";
  return "free";
};

export const useStyleGuides = () => {
  const { data: subscription, isLoading: isLoadingSubscription } =
    useSubscription({});
  const { data: styleGuides, isLoading: isLoadingStyleGuides } =
    useGetStyleGuidesForOrg();
  const { data: ruleDefinitions, isLoading: isLoadingRuleDefinitions } =
    useGetRuleDefinitions();

  const isLoading = useMemo(
    () =>
      isLoadingSubscription || isLoadingStyleGuides || isLoadingRuleDefinitions,
    [isLoadingSubscription, isLoadingStyleGuides, isLoadingRuleDefinitions]
  );

  const userPlanLevel = getUserPlanLevel(
    subscription?.plan,
    subscription?.appsumo ?? false
  );

  return {
    styleGuides,
    isLoading,
    userPlanLevel,
    ruleDefinitions,
  };
};
