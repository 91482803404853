import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { Term } from "../types";

export const getTerms = async (): Promise<Term[]> => {
  const terms = await axios.post("/getTerms");

  // @ts-ignore
  return terms.sort((a, b) => {
    // Sort alphabetically by term
    const termComparison = a.term.localeCompare(b.term);

    // If terms are the same, sort by createdAt ascending
    if (termComparison === 0) {
      return a.createdAt - b.createdAt;
    }

    return termComparison;
  });
};

type QueryFnType = typeof getTerms;

type UseGetTermsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useGetTerms = ({ config }: UseGetTermsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["terms"],
    queryFn: getTerms,
  });
};
