import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { HubTag } from "../types";

export const getHubTags = async (): Promise<HubTag[]> => {
  return axios.post("/getHubTags");
};

type QueryFnType = typeof getHubTags;

type UseGetHubTagsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useGetHubTags = ({ config }: UseGetHubTagsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["hubTags"],
    queryFn: getHubTags,
  });
};
