import { Button } from "@/components/Elements/Button";
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements/Dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/Elements/Tabs";
import { Textarea } from "@/components/Elements/Textarea";
import { getHubDocument } from "@/features/knowledge-hub/api/getHubDocument";
import React, { useState } from "react";
import {
  ToneAnalysisResponse,
  useGenerateToneAnalysis,
} from "../../api/generateToneAnalysis";
import { KnowledgeHubDocList } from "./components/KnowledgeHubDocList";

interface TextVoiceAnalysisDialogProps {
  show: boolean;
  onHide: () => void;
  onApplyAnalysis: (analysis: ToneAnalysisResponse) => void;
}

export const TextVoiceAnalysisDialog: React.FC<
  TextVoiceAnalysisDialogProps
> = ({ show, onHide, onApplyAnalysis }) => {
  const [inputText, setInputText] = useState("");
  const [step, setStep] = useState<"input" | "output">("input");
  const [analysisResult, setAnalysisResult] =
    useState<ToneAnalysisResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<"paste" | "knowledge-hub">(
    "paste"
  );
  const generateToneAnalysisMutation = useGenerateToneAnalysis();
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const handleAnalyze = async () => {
    setError(null);
    try {
      const result = await generateToneAnalysisMutation.mutateAsync({
        input: inputText,
      });
      setAnalysisResult(result);
      setStep("output");
    } catch (error) {
      console.error("Failed to generate tone analysis:", error);
      setError("Failed to generate tone analysis. Please try again.");
    }
  };

  const handleClose = () => {
    setStep("input");
    setInputText("");
    setAnalysisResult(null);
    setError(null);
    setActiveTab("paste");
    onHide();
  };

  const handleApplyAnalysis = () => {
    if (analysisResult) {
      onApplyAnalysis(analysisResult);
      handleClose();
    }
  };

  const handleSelectDocument = async (documentHash: string) => {
    setError(null);
    setIsAnalyzing(true);
    try {
      const fullDocument = await getHubDocument({ hash: documentHash });
      setInputText(fullDocument.content);
      const result = await generateToneAnalysisMutation.mutateAsync({
        input: fullDocument.content,
      });
      setAnalysisResult(result);
      setStep("output");
    } catch (error) {
      console.error("Failed to fetch full document or analyze:", error);
      setError(
        "Failed to load and analyze the selected document. Please try again."
      );
    } finally {
      setIsAnalyzing(false);
    }
  };

  const renderAnalysisResult = () => {
    if (!analysisResult) return null;
    const { tone_analysis } = analysisResult;
    return (
      <div className="space-y-4">
        {Object.entries(tone_analysis).map(([key, value]) => (
          <div key={key} className="border p-4 rounded-md">
            <h3 className="font-bold capitalize">{key.replace("_", " ")}</h3>
            <p>Level: {value.score}/5</p>
            <p>Justification: {value.justification}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Dialog open={show} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[550px] max-h-[90vh] flex flex-col">
        <DialogHeader className="pb-0 flex items-center justify-between">
          <DialogTitle>
            {step === "input" ? "Analyze Text" : "Analysis Result"}
          </DialogTitle>
          <DialogCloseButton close={handleClose} />
        </DialogHeader>
        {step === "input" ? (
          <Tabs
            value={activeTab}
            onValueChange={(value) =>
              setActiveTab(value as "paste" | "knowledge-hub")
            }
            className="flex flex-col flex-grow"
          >
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="paste">Paste</TabsTrigger>
              <TabsTrigger value="knowledge-hub">Knowledge Hub</TabsTrigger>
            </TabsList>
            <DialogDescription className="flex-grow h-[350px]">
              <div className="py-4 flex flex-col h-full">
                <TabsContent
                  value="paste"
                  className="h-full overflow-hidden p-2"
                >
                  <Textarea
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    placeholder="Enter your text here for tone analysis..."
                    className="w-full h-full resize-none overflow-hidden"
                    style={{ marginLeft: 4 }}
                  />
                </TabsContent>
                <TabsContent
                  value="knowledge-hub"
                  className="h-full overflow-auto"
                >
                  <KnowledgeHubDocList
                    onSelectDocument={handleSelectDocument}
                    isAnalyzing={isAnalyzing}
                  />
                </TabsContent>
                {error && <p className="text-red-500 mt-2">{error}</p>}
              </div>
            </DialogDescription>
          </Tabs>
        ) : (
          <DialogDescription className="flex-grow">
            <div className="py-4">{renderAnalysisResult()}</div>
          </DialogDescription>
        )}
        <DialogFooter className="px-4 pb-4">
          {step === "input" && activeTab === "paste" ? (
            <Button
              onClick={handleAnalyze}
              disabled={
                !inputText.trim() || generateToneAnalysisMutation.isLoading
              }
              isLoading={generateToneAnalysisMutation.isLoading}
            >
              Analyze
            </Button>
          ) : step === "output" ? (
            <>
              <Button onClick={handleClose} variant="outline">
                Cancel
              </Button>
              <Button onClick={handleApplyAnalysis}>Apply to Form</Button>
            </>
          ) : null}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
