import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { RuleDefinition } from "../types";

export const getRuleDefinitions = async (): Promise<RuleDefinition[]> => {
  const ruleDefinitions = await axios.post("/getRuleDefinitions");
  // @ts-ignore
  return ruleDefinitions.filter((rule: any) => rule.ruleType !== "pattern");
};

type QueryFnType = typeof getRuleDefinitions;

type UseGetRuleDefinitionsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useGetRuleDefinitions = ({
  config,
}: UseGetRuleDefinitionsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ruleDefinitions"],
    queryFn: getRuleDefinitions,
  });
};
