/**
 * Args
 * {
 *  id: number
 * }
 */

import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";

export const deleteStyleGuide = async ({
  id,
}: {
  id: number;
}): Promise<string> => {
  return axios.post("/deleteStyleGuide", { id });
};

type UseDeleteStyleGuideOptions = {
  config?: MutationConfig<typeof deleteStyleGuide>;
};

export const useDeleteStyleGuide = ({
  config,
}: UseDeleteStyleGuideOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: deleteStyleGuide,
  });
};
