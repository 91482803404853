import React, { useCallback } from "react";
import { Switch } from "../../../../components/Elements";
import {
  BooleanRuleDefinition,
  BooleanStyleGuideRule,
  ChoiceRuleDefinition,
  ChoiceStyleGuideRule,
  NumericRuleDefinition,
  NumericStyleGuideRule,
  SpecificRuleDefinition,
  StyleGuideRule,
} from "../../types";
import { BooleanRuleField } from "./components/BooleanRuleField";
import { ChoiceRuleField } from "./components/ChoiceRuleField";
import { NumericRuleField } from "./components/NumericRuleField";

interface StyleGuideRuleFieldProps {
  ruleDefinition: SpecificRuleDefinition;
  styleGuideRule: StyleGuideRule;
  onRuleChange: (updatedRule: StyleGuideRule) => void;
}

export const StyleGuideRuleField: React.FC<StyleGuideRuleFieldProps> = ({
  ruleDefinition,
  styleGuideRule,
  onRuleChange,
}) => {
  const handleChange = useCallback(
    (newValue: any) => {
      onRuleChange({
        ...styleGuideRule,
        [ruleDefinition.ruleType + "Value"]: newValue,
      });
    },
    [styleGuideRule, ruleDefinition, onRuleChange]
  );

  const handleToggle = useCallback(
    (enabled: boolean) => {
      onRuleChange({
        ...styleGuideRule,
        enabled,
      });
    },
    [styleGuideRule, onRuleChange]
  );

  const renderRuleTypeField = () => {
    switch (ruleDefinition.ruleType) {
      case "boolean":
        return (
          <BooleanRuleField
            ruleDefinition={ruleDefinition as BooleanRuleDefinition}
            styleGuideRule={styleGuideRule as BooleanStyleGuideRule}
            onRuleChange={onRuleChange}
          />
        );
      case "choice":
        return (
          <ChoiceRuleField
            ruleDefinition={ruleDefinition as ChoiceRuleDefinition}
            styleGuideRule={styleGuideRule as ChoiceStyleGuideRule}
            onRuleChange={onRuleChange}
          />
        );
      case "numeric":
        return (
          <NumericRuleField
            ruleDefinition={ruleDefinition as NumericRuleDefinition}
            styleGuideRule={styleGuideRule as NumericStyleGuideRule}
            onRuleChange={onRuleChange}
          />
        );
    }
  };

  return (
    <div
      className={`mb-4 p-4 rounded-md shadow-md transition-all duration-200 ${
        styleGuideRule.enabled
          ? "bg-white dark:bg-zinc-800 border-l-4 border-blue-500 dark:border-blue-400"
          : "bg-gray-100 dark:bg-zinc-900 border-l-4 border-gray-300 dark:border-gray-600"
      }`}
    >
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-sm font-medium text-zinc-900 dark:text-zinc-100">
          {ruleDefinition.name}
        </h3>
        <Switch
          checked={styleGuideRule.enabled}
          onCheckedChange={handleToggle}
          size="sm"
        />
      </div>
      <p className="text-xs text-zinc-500 dark:text-zinc-400 mb-2">
        {ruleDefinition.description}
      </p>
      <div className={styleGuideRule.enabled ? "" : "opacity-50"}>
        {renderRuleTypeField()}
      </div>
    </div>
  );
};
