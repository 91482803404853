import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";

export const extractDocxText = async ({
  file,
}: {
  file: File;
}): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post("/extractDocxText", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

type UseExtractDocxTextOptions = {
  config?: MutationConfig<typeof extractDocxText>;
};

export const useExtractDocxText = ({
  config,
}: UseExtractDocxTextOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: extractDocxText,
  });
};
