import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";
import { HubDocument } from "../types";

export type UpdateHubDocumentFields = {
  tags?: { id: number }[] | null;
  folderId?: number | null;
  content?: string;
  name?: string;
  visibility?: "public" | "private";
};

export const updateHubDocument = async ({
  id,
  updates,
}: {
  id: number;
  updates: UpdateHubDocumentFields;
}): Promise<HubDocument> => {
  return axios.post("/updateHubDocument", { id, ...updates });
};

type UseUpdateHubDocumentOptions = {
  config?: MutationConfig<typeof updateHubDocument>;
};

export const useUpdateHubDocument = ({
  config,
}: UseUpdateHubDocumentOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: updateHubDocument,
  });
};
