import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { useNotificationStore } from "@/stores/notifications";
import React, { useState } from "react";
import { useUpdateTerm } from "../../api/updateTerm";
import { PartOfSpeech, Term, TermRule } from "../../types";
import { TermForm } from "../TermForm";

type TermFormValues = {
  term: string;
  termRule: TermRule;
  partOfSpeech: PartOfSpeech;
  description: string;
  caseSensitive: boolean;
  examples: { acceptable: boolean; exampleText: string }[];
  mistakes: {
    mistakeText: string;
    partOfSpeech: PartOfSpeech;
    caseSensitive: boolean;
  }[];
  primary: boolean;
};

interface UpdateTermFields extends TermFormValues {
  tags: { id: number }[];
}

interface UpdateTermDialogProps {
  open: boolean;
  onClose: () => void;
  term: Term | null;
  checkForDuplicate: (termToCheck: Partial<Term>) => boolean;
  onUpdate: (
    hash: string,
    updates: Omit<Partial<Term>, "tags"> & {
      tags?: { id: number }[];
    }
  ) => void;
}

export const UpdateTermDialog: React.FC<UpdateTermDialogProps> = ({
  open,
  onClose,
  term,
  checkForDuplicate,
  onUpdate,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotificationStore();

  const updateTermMutation = useUpdateTerm();

  const handleUpdateTerm = async (formValues: TermFormValues) => {
    if (!term) {
      return;
    }

    try {
      setIsLoading(true);
      if (checkForDuplicate(formValues)) {
        addNotification({
          type: "error",
          title: "Duplicate term",
          message:
            "A term with the same name and part of speech already exists.",
        });
        setIsLoading(false);
        return;
      }
      const updateFields: UpdateTermFields = {
        ...formValues,
        tags: term.tags.map((tag) => ({ id: tag.id })),
      };
      onUpdate(term.hash, updateFields);
      await updateTermMutation.mutateAsync({
        hash: term.hash,
        updates: updateFields,
      });
      onClose();
      addNotification({
        type: "success",
        title: "Term updated",
        message: "The term has been successfully updated.",
      });
    } catch (error) {
      console.error("Failed to update term:", error);
      addNotification({
        type: "error",
        title: "Failed to update term",
        message: "An error occurred while updating the term.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!term) {
    return null;
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Update Term</DialogTitle>
        </DialogHeader>
        <DialogDescription className="space-y-4 px-4">
          <TermForm
            onSubmit={handleUpdateTerm}
            isLoading={isLoading}
            initialData={term}
          />
        </DialogDescription>
        <DialogFooter className="px-4 pb-4">
          <div className="flex justify-end space-x-2">
            <Button variant="outlineBlur" onClick={onClose} type="button">
              Cancel
            </Button>
            <Button
              type="submit"
              form="term-form"
              disabled={isLoading}
              isLoading={isLoading}
            >
              {isLoading ? "Updating..." : "Update Term"}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateTermDialog;
