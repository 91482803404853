import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@/components/Elements";
import { useNotificationStore } from "@/stores/notifications";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useSaveStyleGuideRule } from "../../api/saveStyleGuideRule";
import { useUpdateStyleGuide } from "../../api/updateStyleGuide";
import {
  BooleanStyleGuideRule,
  ChoiceStyleGuideRule,
  NumericStyleGuideRule,
  SpecificRuleDefinition,
  StyleGuide,
  StyleGuideRule,
} from "../../types";
import { StyleGuideRuleField } from "../StyleGuideRuleField";

interface StyleGuideFormProps {
  initialData: StyleGuide;
  ruleDefinitions: SpecificRuleDefinition[];
  onSuccess?: (styleGuide: StyleGuide) => void;
}

export const StyleGuideForm: React.FC<StyleGuideFormProps> = ({
  initialData,
  ruleDefinitions,
  onSuccess = () => {},
}) => {
  const queryClient = useQueryClient();
  const updateStyleGuideMutation = useUpdateStyleGuide();
  const saveStyleGuideRuleMutation = useSaveStyleGuideRule();
  const { addNotification } = useNotificationStore();

  const form = useForm<
    Omit<StyleGuide, "id" | "createdAt" | "updatedAt" | "orgId" | "rules">
  >({
    defaultValues: {
      name: initialData?.name || "",
      description: initialData?.description || "",
    },
  });

  const [rules, setRules] = useState<StyleGuideRule[]>(initialData.rules);
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    setRules(initialData.rules);
  }, [initialData]);

  useEffect(() => {
    form.reset({
      name: initialData?.name || "",
      description: initialData?.description || "",
    });
    setFormChanged(false);
  }, [initialData, form]);

  const handleFormChange = () => {
    const { name, description } = form.getValues();
    const hasChanged =
      name !== initialData.name || description !== initialData.description;
    setFormChanged(hasChanged);
  };

  const handleSubmit = async (
    data: Omit<StyleGuide, "id" | "createdAt" | "updatedAt" | "orgId" | "rules">
  ) => {
    try {
      await updateStyleGuideMutation.mutateAsync({
        id: initialData.id,
        updates: data,
      });
      await queryClient.invalidateQueries(["styleGuidesForOrg"]);
      addNotification({
        title: "Style Guide Updated",
        message: "Your style guide has been updated successfully.",
        type: "success",
      });
      onSuccess({
        ...initialData,
        ...data,
        rules: rules,
      });
    } catch (error) {
      addNotification({
        title: "Error",
        message: "Failed to update style guide. Please try again.",
        type: "error",
      });
    }
  };

  const handleRuleChange = async (updatedRule: StyleGuideRule) => {
    try {
      const payload = {
        guideId: updatedRule.guideId,
        ruleId: updatedRule.ruleId,
        enabled: updatedRule.enabled,
        type: updatedRule.type,
        // @ts-ignore
        booleanValue: updatedRule.booleanValue,
        // @ts-ignore
        choiceValue: updatedRule.choiceValue,
        // @ts-ignore
        numericValue: updatedRule.numericValue,
      };

      const savedRule = await saveStyleGuideRuleMutation.mutateAsync(payload);

      setRules((prevRules) => {
        const newRules = prevRules.map((rule) =>
          rule.ruleId === savedRule.ruleId ? savedRule : rule
        );
        if (!prevRules.some((rule) => rule.ruleId === savedRule.ruleId)) {
          newRules.push(savedRule);
        }
        return newRules;
      });

      addNotification({
        title: "Rule Updated",
        message: "The rule has been updated successfully.",
        type: "success",
      });
    } catch (error) {
      console.error("Error updating rule:", error);
      addNotification({
        title: "Error",
        message: "Failed to update rule. Please try again.",
        type: "error",
      });
    }
  };

  const createDefaultRule = (
    ruleDefinition: SpecificRuleDefinition
  ): Omit<StyleGuideRule, "id" | "updatedAt"> => {
    const baseRule = {
      guideId: initialData.id,
      ruleId: ruleDefinition.id,
      enabled: false, // Set to false by default for new rules
      type: ruleDefinition.ruleType,
    };

    switch (ruleDefinition.ruleType) {
      case "boolean":
        return {
          ...baseRule,
          booleanValue: ruleDefinition.defaultBooleanValue || false,
        } as Omit<BooleanStyleGuideRule, "id" | "updatedAt">;
      case "choice":
        return {
          ...baseRule,
          choiceValue: ruleDefinition.defaultChoiceValue || "",
        } as Omit<ChoiceStyleGuideRule, "id" | "updatedAt">;
      case "numeric":
        return {
          ...baseRule,
          numericValue: ruleDefinition.defaultNumericValue || 0,
        } as Omit<NumericStyleGuideRule, "id" | "updatedAt">;
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="name"
          rules={{ required: "Name is required" }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Style Guide Name</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Enter a name for your style guide"
                  onChange={(e) => {
                    field.onChange(e);
                    handleFormChange();
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Describe your style guide"
                  onChange={(e) => {
                    field.onChange(e);
                    handleFormChange();
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-end">
          <Button
            type="submit"
            isLoading={updateStyleGuideMutation.isLoading}
            disabled={!formChanged || !form.formState.isValid}
          >
            Update Name & Description
          </Button>
        </div>

        {ruleDefinitions.map((ruleDefinition) => {
          const existingRule = rules.find(
            (rule) => rule.ruleId === ruleDefinition.id
          );
          const ruleToUse = existingRule || createDefaultRule(ruleDefinition);
          return (
            <StyleGuideRuleField
              key={ruleDefinition.id}
              ruleDefinition={ruleDefinition}
              styleGuideRule={ruleToUse as StyleGuideRule}
              onRuleChange={handleRuleChange}
            />
          );
        })}
      </form>
    </Form>
  );
};
