import { Combobox } from "@/components/Elements";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { UpdateHubDocumentFields } from "../../api/updateHubDocument";
import { HubDocument, HubTag } from "../../types";

interface HubTagsCellProps {
  document: HubDocument;
  tags: {
    label: string;
    value: string;
    color?: string;
    onClick: () => void;
    icon?: React.ReactNode;
    type?: "button" | "input";
  }[];
  onFieldUpdate?: (
    updates: { field: keyof UpdateHubDocumentFields; value: any }[],
    data: HubDocument | HubDocument[]
  ) => void;
}

const TagPill = ({ tag, onRemove }: { tag: HubTag; onRemove: () => void }) => {
  return (
    <span
      className="inline-flex items-center gap-1 rounded-full px-2 py-1 text-xs"
      style={{
        backgroundColor: `${tag.color}1A`, // 10% opacity
        color: tag.color,
      }}
    >
      <span className="truncate">{tag.name}</span>
      <button
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
        className="hover:bg-black/5 rounded-full p-0.5"
      >
        <XMarkIcon className="h-3 w-3" />
      </button>
    </span>
  );
};

const AddTagButton = () => {
  return (
    <span className="inline-flex items-center rounded-full px-2 py-1 text-2xs text-zinc-400 dark:text-zinc-600 hover:text-zinc-900 dark:hover:text-white">
      <PlusIcon className="mr-1 h-3 w-3" />
      Add tag
    </span>
  );
};

export const HubTagsCell = ({
  document,
  tags,
  onFieldUpdate,
}: HubTagsCellProps) => {
  const documentTags = document.tags || [];
  const availableTags = tags.filter(
    (tag) =>
      tag.value === "New Tag" ||
      !documentTags.some((docTag) => docTag.id === parseInt(tag.value, 10))
  );

  const handleTagRemove = (tagId: number) => {
    if (!onFieldUpdate) return;

    const newTags = documentTags.filter((tag) => tag.id !== tagId);
    onFieldUpdate(
      [{ field: "tags", value: newTags.map((tag) => ({ id: tag.id })) }],
      document
    );
  };

  return (
    <div className="flex flex-wrap gap-1 min-h-[28px] items-center">
      {documentTags.map((tag) => (
        <TagPill
          key={tag.id}
          tag={tag}
          onRemove={() => handleTagRemove(tag.id)}
        />
      ))}
      {availableTags.length > 0 && (
        <Combobox
          data={document}
          // @ts-ignore
          options={availableTags}
          buttonVariant="buttonNode"
          buttonClassName="rounded-full"
          showEndIcon={false}
          enableSearch
          searchPlaceholder="Search tags..."
          trigger={<AddTagButton />}
        />
      )}
    </div>
  );
};
