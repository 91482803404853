import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";

export interface TonePreviewParams {
  targetAudience: string | null;
  formalityLevel: number;
  politenessLevel: number;
  emotionLevel: number;
  assertivenessLevel: number;
  context: string | null;
}

export interface TonePreviewResponse {
  content: string;
}

export const generateTonePreview = async (
  params: TonePreviewParams
): Promise<TonePreviewResponse> => {
  return axios.post("/generateTonePreview", params);
};

type UseGenerateTonePreviewOptions = {
  config?: MutationConfig<typeof generateTonePreview>;
};

export const useGenerateTonePreview = ({
  config,
}: UseGenerateTonePreviewOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: generateTonePreview,
  });
};
