import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { Button } from "@/components/Elements/Button";
import { Checkbox } from "@/components/Elements/Checkbox";
import { queryClient } from "@/lib/react-query";
import React, { useEffect, useState } from "react";
import { updateHubDocument } from "../../api/updateHubDocument";
import { HubDocument } from "../../types";

interface SelectPrimaryDocumentsDialogProps {
  open: boolean;
  onClose: () => void;
  documents: HubDocument[];
  documentLimit: number;
}

const SelectPrimaryDocumentsDialog: React.FC<
  SelectPrimaryDocumentsDialogProps
> = ({ open, onClose, documents, documentLimit }) => {
  const [selectedDocuments, setSelectedDocuments] = useState<HubDocument[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // Initialize with currently primary documents
    setSelectedDocuments(documents.filter((doc) => doc.primary));
  }, [documents]);

  const handleToggleDocument = (document: HubDocument) => {
    if (selectedDocuments.some((doc) => doc.id === document.id)) {
      setSelectedDocuments(
        selectedDocuments.filter((doc) => doc.id !== document.id)
      );
    } else if (selectedDocuments.length < documentLimit) {
      setSelectedDocuments([...selectedDocuments, document]);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    for (const document of selectedDocuments) {
      await updateHubDocument({
        id: document.id,
        updates: { primary: true },
      });
    }
    queryClient.invalidateQueries(["hubFoldersForOrg"]);
    queryClient.invalidateQueries(["hubDocuments"]);
    onClose();
    setIsSaving(false);
  };

  return (
    <Dialog open={open}>
      <DialogContent className="sm:max-w-[525px] p-0 bg-white dark:bg-zinc-900 border border-zinc-200 dark:border-zinc-700">
        <DialogHeader className="px-6 pt-6 pb-0">
          <DialogTitle className="text-xl font-semibold text-zinc-900 dark:text-zinc-100">
            Select Primary Documents
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="px-6 pt-4">
          <p className="text-sm text-zinc-600 dark:text-zinc-400">
            Your plan supports {documentLimit} active Knowledge Hub document
            {documentLimit > 1 ? "s" : ""}. Please select which ones to keep
            active. Inactive documents will be saved but won't be accessible
            without upgrading.
          </p>
        </DialogDescription>
        <div className="px-6 py-4 max-h-[400px] overflow-y-auto">
          {documents.map((document) => (
            <div key={document.id} className="flex items-start space-x-2 py-2">
              <Checkbox
                id={`document-${document.id}`}
                checked={selectedDocuments.some(
                  (doc) => doc.id === document.id
                )}
                onCheckedChange={() => handleToggleDocument(document)}
                disabled={
                  (selectedDocuments.length >= documentLimit &&
                    !selectedDocuments.some((doc) => doc.id === document.id)) ||
                  document.primary
                }
              />
              <div className="flex flex-col">
                <label
                  htmlFor={`document-${document.id}`}
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {document.name}
                </label>
              </div>
            </div>
          ))}
        </div>
        <DialogFooter className="px-6 pb-6 flex items-center justify-end space-x-2">
          <Button
            type="button"
            variant="primaryBlur"
            className="bg-blue-600 text-white hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600"
            onClick={handleSave}
            disabled={isSaving || selectedDocuments.length !== documentLimit}
            isLoading={isSaving}
          >
            Save Selection
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SelectPrimaryDocumentsDialog;
