import { useMemo } from "react";
import { useSubscription } from "../auth/api/getSubscription";
import { useGetBrandVoicesForOrg } from "./api/getBrandVoicesForOrg";

export const BRAND_VOICE_LIMITS = {
  free: 1,
  basic: 1,
  team: 5,
  enterprise: Infinity,
} as const;

const getBrandVoiceLimit = (plan?: string, appsumo?: boolean): number => {
  if (appsumo) return BRAND_VOICE_LIMITS.team;
  if (!plan || plan.includes("solo")) return BRAND_VOICE_LIMITS.free;
  if (plan.includes("team")) return BRAND_VOICE_LIMITS.team;
  if (plan.includes("enterprise")) return BRAND_VOICE_LIMITS.enterprise;
  return BRAND_VOICE_LIMITS.basic;
};

export const useBrandVoices = () => {
  const { data: subscription, isLoading: isLoadingSubscription } =
    useSubscription({});
  const { data: allBrandVoices, isLoading: isLoadingBrandVoices } =
    useGetBrandVoicesForOrg();

  const brandVoiceLimit = getBrandVoiceLimit(
    subscription?.plan,
    subscription?.appsumo ?? false
  );

  const currentBrandVoiceCount = useMemo(
    () => allBrandVoices?.length || 0,
    [allBrandVoices]
  );

  const isLoading = useMemo(
    () => isLoadingSubscription || isLoadingBrandVoices,
    [isLoadingSubscription, isLoadingBrandVoices]
  );

  const overLimit = currentBrandVoiceCount > brandVoiceLimit;

  const useableBrandVoices = useMemo(() => {
    if (overLimit) return allBrandVoices?.filter((voice) => voice.primary);
    return allBrandVoices;
  }, [overLimit, allBrandVoices, currentBrandVoiceCount, brandVoiceLimit]);

  const needToSetPrimary = useMemo(() => {
    return overLimit && !useableBrandVoices?.length;
  }, [overLimit, useableBrandVoices]);

  return {
    brandVoiceLimit,
    currentBrandVoiceCount,
    isLoading,
    allBrandVoices,
    useableBrandVoices,
    needToSetPrimary,
  };
};
