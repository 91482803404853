import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";
import { HubDocument } from "../types";

export const createHubDocument = async ({
  name,
  content,
  visibility,
  folderId,
  tags,
}: {
  name: string;
  content: string;
  visibility: "private" | "team";
  folderId?: number;
  tags?: { id: number }[];
}): Promise<HubDocument> => {
  return axios.post("/createHubDocument", {
    name,
    content,
    visibility,
    folderId,
    tags,
  });
};

type UseCreateHubDocumentOptions = {
  config?: MutationConfig<typeof createHubDocument>;
};

export const useCreateHubDocument = ({
  config,
}: UseCreateHubDocumentOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: createHubDocument,
  });
};
