import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { HubFolder } from "../types";

export const getHubFoldersForOrg = async (): Promise<HubFolder[]> => {
  return axios.post("/getHubFoldersForOrg");
};

type QueryFnType = typeof getHubFoldersForOrg;
type UseGetHubFoldersForOrgOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useGetHubFoldersForOrg = ({
  config,
}: UseGetHubFoldersForOrgOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["hubFoldersForOrg"],
    queryFn: getHubFoldersForOrg,
  });
};
