import { axios } from "@/lib/axios";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useMutation } from "react-query";

export const deleteTerm = async ({
  hash,
}: {
  hash: string;
}): Promise<string> => {
  return axios.post("/deleteTerm", { hash });
};

type UseDeleteTermOptions = {
  config?: MutationConfig<typeof deleteTerm>;
};

export const useDeleteTerm = ({ config }: UseDeleteTermOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: deleteTerm,
    onSuccess: () => {
      queryClient.invalidateQueries(["terms"]);
    },
  });
};
