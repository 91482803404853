import { cn } from "@/utils/style";
import * as SliderPrimitive from "@radix-ui/react-slider";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";
import { TbInfoCircle } from "react-icons/tb";
import { FormControl, FormField, FormItem, FormLabel } from "../Form";

interface LabelItem {
  label: string;
  tooltip?: React.ReactNode;
}

interface NotchedSliderProps {
  labels: LabelItem[];
  name: string;
  control: any;
  label?: string;
  className?: string;
  headerTooltip?: React.ReactNode;
}

const NotchedSlider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  NotchedSliderProps
>(({ className, labels, name, control, label, headerTooltip }, ref) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {label && (
            <div className="flex items-center space-x-2">
              <FormLabel>{label}</FormLabel>
              {headerTooltip && (
                <TooltipPrimitive.Provider>
                  <TooltipPrimitive.Root>
                    <TooltipPrimitive.Trigger asChild>
                      <span className="cursor-help text-zinc-400 hover:text-zinc-600">
                        <TbInfoCircle className="w-4 h-4" />
                      </span>
                    </TooltipPrimitive.Trigger>
                    <TooltipPrimitive.Content
                      className="bg-zinc-800 text-white px-4 py-2 rounded text-sm max-w-xs z-[99999]"
                      sideOffset={5}
                    >
                      {headerTooltip}
                      <TooltipPrimitive.Arrow className="fill-zinc-800" />
                    </TooltipPrimitive.Content>
                  </TooltipPrimitive.Root>
                </TooltipPrimitive.Provider>
              )}
            </div>
          )}
          <FormControl>
            <div className="relative pt-4 pb-6 px-12">
              <div className="relative w-full">
                <SliderPrimitive.Root
                  className={cn(
                    "relative flex w-full touch-none select-none items-center",
                    className
                  )}
                  max={labels.length - 1}
                  step={1}
                  value={field.value ? [field.value] : undefined}
                  onValueChange={(value) => {
                    field.onChange(value[0]);
                  }}
                  ref={ref}
                >
                  <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-zinc-100 border">
                    <SliderPrimitive.Range className="absolute h-full bg-emerald-600" />
                  </SliderPrimitive.Track>
                  <SliderPrimitive.Thumb className="block h-3.5 w-3.5 rounded-full bg-emerald-600 hover:bg-emerald-700 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
                </SliderPrimitive.Root>

                {/* Labels */}
                <div
                  className="absolute w-full flex justify-between mt-2"
                  style={{ left: 0, right: 0 }}
                >
                  {labels.map((labelItem, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center"
                      style={{
                        position: "absolute",
                        left: `${(index / (labels.length - 1)) * 100}%`,
                        transform: "translateX(-50%)",
                        width: "100%",
                        maxWidth: "120px",
                      }}
                    >
                      <div className="h-1 w-1 rounded-full bg-zinc-300 mb-1" />
                      <TooltipPrimitive.Provider>
                        <TooltipPrimitive.Root>
                          <TooltipPrimitive.Trigger asChild>
                            <span
                              className="text-xs text-zinc-600 dark:text-zinc-400 text-center whitespace-normal cursor-pointer hover:text-zinc-900 dark:hover:text-zinc-100 transition-colors"
                              onClick={() => field.onChange(index)}
                            >
                              {labelItem.label}
                            </span>
                          </TooltipPrimitive.Trigger>
                          {labelItem.tooltip && (
                            <TooltipPrimitive.Content
                              className="bg-zinc-800 text-white px-2 py-1 rounded text-xs"
                              sideOffset={5}
                            >
                              {labelItem.tooltip}
                              <TooltipPrimitive.Arrow className="fill-zinc-800" />
                            </TooltipPrimitive.Content>
                          )}
                        </TooltipPrimitive.Root>
                      </TooltipPrimitive.Provider>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </FormControl>
        </FormItem>
      )}
    />
  );
});

NotchedSlider.displayName = "NotchedSlider";

export { NotchedSlider };
