import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";
import { StyleGuide } from "../types";

export const createStyleGuide = async (
  styleGuide: Omit<
    StyleGuide,
    "id" | "createdAt" | "updatedAt" | "orgId" | "rules"
  >
): Promise<StyleGuide> => {
  return axios.post("/createStyleGuide", styleGuide);
};

type UseCreateStyleGuideOptions = {
  config?: MutationConfig<typeof createStyleGuide>;
};

export const useCreateStyleGuide = ({
  config,
}: UseCreateStyleGuideOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: createStyleGuide,
  });
};
