import { axios } from "@/lib/axios";
import {
  ExtractFnReturnType,
  queryClient,
  QueryConfig,
} from "@/lib/react-query";
import { useQuery } from "react-query";

export const clearPrimaryTerms = async (): Promise<void> => {
  await axios.post("/clearPrimaryTerms");
  await queryClient.invalidateQueries(["terms"]);
};

type QueryFnType = typeof clearPrimaryTerms;

type UseClearPrimaryTermsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useClearPrimaryTerms = ({
  config,
}: UseClearPrimaryTermsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["clearPrimaryTerms"],
    queryFn: clearPrimaryTerms,
    onSuccess: () => {
      queryClient.invalidateQueries(["terms"]);
    },
  });
};
