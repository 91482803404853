/**
 * Args
 * {
 *  id: number
 *  name: string
 *  description: string
 * }
 */

import { axios } from "@/lib/axios";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useMutation } from "react-query";
import { StyleGuide } from "../types";

export type UpdateStyleGuideFields = Partial<
  Omit<StyleGuide, "id" | "createdAt" | "updatedAt" | "orgId" | "rules">
>;

export const updateStyleGuide = async ({
  id,
  updates,
}: {
  id: number;
  updates: UpdateStyleGuideFields;
}): Promise<StyleGuide> => {
  return axios.post("/updateStyleGuide", { id, ...updates });
};

type UseUpdateStyleGuideOptions = {
  config?: MutationConfig<typeof updateStyleGuide>;
};

export const useUpdateStyleGuide = ({
  config,
}: UseUpdateStyleGuideOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: updateStyleGuide,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["styleGuidesForOrg"]);
      queryClient.invalidateQueries(["styleGuide", data.id]);
    },
  });
};
