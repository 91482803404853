import { Button } from "@/components/Elements";
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements/Dialog";
import { FraseResearchBot } from "@/components/Elements/Logo/FraseResearchBot";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import { ContentLayout } from "@/components/Layout";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetBrandVoicesForOrg } from "../api/getBrandVoicesForOrg";
import { BrandVoiceForm } from "../components/BrandVoiceForm";
import { BrandVoiceList } from "../components/BrandVoiceList";
import { ConfirmDeleteBrandVoiceDialog } from "../components/ConfirmDeleteBrandVoiceDialog";
import { BrandVoice as BrandVoiceType } from "../types";

export const BrandVoice = () => {
  const { data: subscription, isLoading: isSubscriptionLoading } =
    useSubscription({});
  const navigate = useNavigate();
  const { data: brandVoices, isLoading } = useGetBrandVoicesForOrg();

  const [selectedBrandVoice, setSelectedBrandVoice] = useState<
    BrandVoiceType | "ADD_NEW"
  >("ADD_NEW");
  const [showDeleteDialog, setShowDeleteDialog] =
    useState<BrandVoiceType | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false);
  const [pendingBrandVoice, setPendingBrandVoice] = useState<
    BrandVoiceType | "ADD_NEW" | null
  >(null);

  const handleSelectBrandVoice = useCallback(
    (brandVoice: BrandVoiceType | "ADD_NEW") => {
      if (hasUnsavedChanges) {
        setShowUnsavedChangesDialog(true);
        setPendingBrandVoice(brandVoice);
      } else {
        setSelectedBrandVoice(brandVoice);
      }
    },
    [hasUnsavedChanges]
  );

  const handleConfirmSwitch = useCallback(() => {
    if (pendingBrandVoice) {
      setSelectedBrandVoice(pendingBrandVoice);
      setHasUnsavedChanges(false);
    }
    setShowUnsavedChangesDialog(false);
    setPendingBrandVoice(null);
  }, [pendingBrandVoice]);

  const handleCancelSwitch = useCallback(() => {
    setShowUnsavedChangesDialog(false);
    setPendingBrandVoice(null);
  }, []);

  const handleFormSuccess = useCallback((newBrandVoice: BrandVoiceType) => {
    setSelectedBrandVoice(newBrandVoice);
    setHasUnsavedChanges(false);
  }, []);

  const handleDeleteSuccess = useCallback(() => {
    if (
      selectedBrandVoice !== "ADD_NEW" &&
      showDeleteDialog?.id === selectedBrandVoice.id
    ) {
      setSelectedBrandVoice("ADD_NEW");
    }
    setShowDeleteDialog(null);
  }, [selectedBrandVoice, showDeleteDialog]);

  const selectedBrandVoiceId = useMemo(
    () =>
      selectedBrandVoice === "ADD_NEW"
        ? "ADD_NEW"
        : selectedBrandVoice?.id || null,
    [selectedBrandVoice]
  );

  const handleFormChange = useCallback((hasChanges: boolean) => {
    setHasUnsavedChanges(hasChanges);
  }, []);

  if (isLoading || isSubscriptionLoading) {
    return (
      <ContentLayout title="Brand Voice">
        <div className="relative flex items-center w-full h-full justify-center">
          <LogoSpinner variant="md" loadingText="Loading Brand Voice..." />
        </div>
      </ContentLayout>
    );
  }

  const isTeamOrAppSumo =
    (subscription?.plan?.includes("team") || subscription?.appsumo) ?? false;

  if (!isTeamOrAppSumo) {
    return (
      <ContentLayout title="Brand Voice">
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="bg-white dark:bg-zinc-800 rounded-md p-10 shadow-glow max-w-2xl border space-y-6 dark:border-zinc-700">
            <FraseResearchBot />
            <div className="space-y-6">
              <div className="space-y-4">
                <h1 className="text-2xl font-medium dark:text-white">
                  Discover the Power of Frase Brand Voice
                </h1>
                <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                  Your brand's voice is more than just words—it's the
                  personality that connects you with your audience. Frase Brand
                  Voice helps you create content that feels authentic,
                  consistent, and uniquely yours.
                </p>
              </div>

              <div className="space-y-4">
                <h2 className="text-lg font-medium dark:text-white">
                  Why Customers Love Brand Voice:
                </h2>
                <ul className="space-y-2 text-[15px] text-zinc-600 dark:text-zinc-200 pl-6">
                  <li className="list-disc">
                    <strong>Build a Strong Identity:</strong> Ensure every piece
                    of content reflects your brand's personality.
                  </li>
                  <li className="list-disc">
                    <strong>Speak Their Language:</strong> Adjust your tone to
                    resonate with different audience segments.
                  </li>
                  <li className="list-disc">
                    <strong>Unite Your Team:</strong> Empower your team to write
                    in one cohesive voice.
                  </li>
                </ul>
                <div style={{ marginTop: "26px" }}>
                  <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                    Upgrade to the Frase Team plan today and start crafting
                    content that connects and converts.
                  </p>
                </div>
              </div>
              <Button
                variant="primaryBlur"
                size="sm"
                className="bg-emerald-100 w-full"
                onClick={() => {
                  navigate("/app/settings/plans");
                }}
              >
                Upgrade to Team plan to access Brand Voice
              </Button>
            </div>
          </div>
        </div>
      </ContentLayout>
    );
  }

  return (
    <ContentLayout title="Brand Voice">
      <div className="flex h-full">
        <div className="w-[280px] overflow-y-auto border-r border-gray-200 dark:border-gray-700">
          <BrandVoiceList
            brandVoices={brandVoices || []}
            onSelectBrandVoice={handleSelectBrandVoice}
            onDeleteBrandVoice={setShowDeleteDialog}
            selectedBrandVoiceId={selectedBrandVoiceId}
          />
        </div>
        <div className="flex-1 p-4 overflow-y-auto">
          <BrandVoiceForm
            initialData={
              selectedBrandVoice === "ADD_NEW" ? undefined : selectedBrandVoice
            }
            onSuccess={handleFormSuccess}
            onFormChange={handleFormChange}
          />
        </div>
        {showDeleteDialog && (
          <ConfirmDeleteBrandVoiceDialog
            show={Boolean(showDeleteDialog)}
            onHide={() => setShowDeleteDialog(null)}
            brandVoice={showDeleteDialog}
            onSuccess={handleDeleteSuccess}
          />
        )}
        <Dialog
          open={showUnsavedChangesDialog}
          onOpenChange={handleCancelSwitch}
        >
          <DialogContent>
            <DialogHeader className="pb-0 flex items-center justify-between">
              <DialogTitle>Unsaved Changes</DialogTitle>
              <DialogCloseButton close={handleCancelSwitch} />
            </DialogHeader>
            <DialogDescription className="px-4">
              You have unsaved changes. Are you sure you want to switch to
              another Brand Voice? Your changes will be lost.
            </DialogDescription>
            <DialogFooter className="px-4 pb-4">
              <Button variant="outlineBlur" onClick={handleCancelSwitch}>
                Cancel
              </Button>
              <Button variant="primaryBlur" onClick={handleConfirmSwitch}>
                Switch
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </ContentLayout>
  );
};
