import {
  Button,
  Combobox,
  FilterTag,
  FraseBot,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableFilter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Elements";
import { Navigation } from "@/components/Layout/MobileNavigation";
import { cn } from "@/lib/merge";
import { flexRender } from "@tanstack/react-table";
import { TrashIcon } from "lucide-react";
import { useRef, useState } from "react";
import { Term, TermTag } from "../../types";
import CreateTermDialog from "../CreateTermDialog";
import CreateTermTagDialog from "../CreateTermTagDialog";
import { DeleteTermsButton } from "../DeleteTermsButton";
import UpdateTermDialog from "../UpdateTermDialog";
import { useTermsTable } from "./hooks/useTermsTable";

interface TermsTableProps {
  terms?: Term[];
  tags?: TermTag[];
  termLimit: number;
  currentTermCount: number;
  onLimitReached: () => void;
  checkForDuplicate: (termToCheck: Partial<Term>) => boolean;
}

const TermsTable = ({
  terms = [],
  tags = [],
  termLimit,
  currentTermCount,
  onLimitReached,
  checkForDuplicate,
}: TermsTableProps) => {
  const tableRef = useRef<HTMLTableElement | null>(null);
  const overLimit = currentTermCount > termLimit;
  const [termToEdit, setTermToEdit] = useState<Term | null>(null);

  const {
    processedTags,
    filters,
    table,
    removeFilter,
    selectedFilters,
    setSelectedFilters,
    isCreateHubTagDialogOpen,
    finalizeCreateHubTag,
    closeCreateHubTagDialog,
    page,
    setPage,
    handleOptimisticUpdate,
  } = useTermsTable({
    overLimit,
    terms,
    tags,
  });

  return (
    <div className="flex h-full w-full">
      <div className="flex flex-col h-full w-full relative">
        <div className="flex flex-col h-fit">
          <div
            className="flex justify-between items-center px-6 h-14 w-full border-b dark:border-zinc-800"
            ref={tableRef}
          >
            <div className="flex items-center">
              <Navigation />

              <span className="text-sm font-medium dark:text-white items-center">
                <span className="flex items-center mr-4 [(:first-child)]:ml-1">
                  {table.getIsSomeRowsSelected() ? (
                    <>
                      {table.getFilteredSelectedRowModel().rows.length} terms
                      selected
                    </>
                  ) : (
                    <>Terms</>
                  )}
                </span>
              </span>
              {!table.getIsSomeRowsSelected() &&
                (table.getRowModel().rows.length > 0 ||
                  selectedFilters.length > 0) && (
                  <TableFilter
                    setSelectedFilters={setSelectedFilters}
                    filters={filters}
                    selectedFilters={selectedFilters}
                    variant="default"
                    table={table}
                  />
                )}
              {table.getFilteredSelectedRowModel().rows.length > 0 && (
                <>
                  <Combobox
                    data={table.getFilteredSelectedRowModel().rows}
                    // @ts-ignore
                    options={processedTags}
                    fixedValue={"Assign tag"}
                    showEndIcon={false}
                    enableSearch
                    className="h-7 ml-2 min-w-fit rounded-md backdrop-blur-lg bg-white/90 dark:bg-zinc-900/50 shadow-sm dark:shadow-lg ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-10 ring-opacity-5 py-1 px-3 text-zinc-600 ring-1 ring-inset ring-zinc-900/10 hover:bg-zinc-900/2.5 hover:text-zinc-900 dark:text-white dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none"
                  />
                  {
                    <DeleteTermsButton
                      table={table}
                      entityHashes={[
                        ...table
                          .getFilteredSelectedRowModel()
                          .rows.map((row) => row.original.hash),
                      ]}
                      entityTitles={[
                        ...table
                          .getFilteredSelectedRowModel()
                          .rows.map((row) => row.original.term),
                      ]}
                    />
                  }
                </>
              )}
            </div>
            <div className="flex">
              <CreateTermDialog
                termLimit={termLimit}
                currentTermCount={currentTermCount}
                onLimitReached={onLimitReached}
                checkForDuplicate={checkForDuplicate}
              />
            </div>
          </div>
          {selectedFilters.length > 0 && (
            <div className="border-b border-zinc-200/80 dark:border-white/10 pl-5 flex items-center space-x-2 h-14">
              {selectedFilters.map((filter, idx) => (
                <FilterTag
                  key={filter.value}
                  variant="small"
                  color={filter.color || "zinc"}
                  startIcon={filter.icon}
                  onClose={(e: any) => {
                    const column = table.getColumn(filter.parent || "");
                    column?.setFilterValue(undefined);
                    removeFilter(idx);
                    e.stopPropagation();
                  }}
                  className="h-7"
                  textClassName="text-xs"
                >
                  {filter.label}
                </FilterTag>
              ))}
              <TableFilter
                setSelectedFilters={setSelectedFilters}
                filters={filters}
                selectedFilters={selectedFilters}
                table={table}
                variant="plus"
              />
            </div>
          )}
        </div>

        {table.getRowModel().rows.length === 0 && selectedFilters.length > 0 ? (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <p className="text-md font-base text-zinc-600 dark:text-zinc-400">
              No terms found
            </p>
            <Button
              className="mt-4"
              variant="outlineBlur"
              startIcon={<TrashIcon />}
              size="2xs"
              onClick={() => setSelectedFilters([])}
            >
              Clear Filters
            </Button>
          </div>
        ) : table.getRowModel().rows?.length || selectedFilters.length > 0 ? (
          <div
            className={cn(
              "flex w-full overflow-auto pb-20",
              selectedFilters.length > 0
                ? "max-h-[calc(100vh-112px)]"
                : "max-h-[calc(100vh-105px)]"
            )}
          >
            <Table className="w-full">
              <TableHeader
                className="sticky top-0 z-[9] bg-white py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8
                dark:bg-zinc-900 dark:text-white"
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          key={header.id}
                          style={{
                            boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                            width: header.getSize(),
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody className="overflow-y-scroll">
                {table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.original.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={cn(
                      "hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer"
                    )}
                    onClick={() => {
                      if (overLimit && !row.original.primary) {
                        return;
                      }
                      setTermToEdit(row.original);
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <div className="bg-white dark:bg-zinc-800 rounded-md p-10 shadow-glow max-w-lg border space-y-4 dark:border-zinc-700">
              <FraseBot />
              {terms.length === 0 && (
                <div className="space-y-4">
                  <h1 className="text-lg font-medium dark:text-white">
                    Welcome to Frase Terms
                  </h1>
                  <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                    Ensure consistent, on-brand content with Frase Terms. Define
                    your own glossary of allowed and disallowed words and
                    phrases to maintain accuracy, tone, and messaging across
                    every piece of content.
                  </p>
                  <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                    Here's how customers use Frase Terms to refine their
                    content:
                  </p>
                  <ul className="list-disc pl-5 text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                    <li>
                      Create a unified voice by enforcing approved terminology
                      across teams.
                    </li>
                    <li>
                      Avoid mistakes by flagging disallowed terms during the
                      writing process.
                    </li>
                    <li>
                      Strengthen brand consistency with clear guidelines for
                      content creators.
                    </li>
                  </ul>
                  <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                    Empower your team with Frase Terms to create polished,
                    professional content every time.
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="absolute bottom-0 bg-white border-t dark:border-t-zinc-800 h-[48px] max-h-[48px] w-full dark:bg-zinc-900">
          <Pagination
            type="term"
            documentsCount={terms.length}
            currentPage={page}
            itemsPerPage={table.getState().pagination.pageSize}
            totalItems={
              table.getFilteredRowModel().rows.length ||
              table.getRowModel().rows.length
            }
            onPageChange={(index) => {
              setPage(index);
              table.setPageIndex(index - 1);
            }}
            onItemsPerPageChange={(pageSize) => table.setPageSize(pageSize)}
            table={table}
          />
        </div>
      </div>
      <CreateTermTagDialog
        open={isCreateHubTagDialogOpen}
        onCancel={closeCreateHubTagDialog}
        onFinalize={finalizeCreateHubTag}
      />
      <UpdateTermDialog
        open={!!termToEdit}
        onClose={() => setTermToEdit(null)}
        term={termToEdit}
        checkForDuplicate={checkForDuplicate}
        onUpdate={handleOptimisticUpdate}
      />
    </div>
  );
};

export default TermsTable;
