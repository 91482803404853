export enum TermRule {
  APPROVED = "APPROVED",
  DONT_USE = "DONT_USE",
  PENDING = "PENDING",
  USE_CAREFULLY = "USE_CAREFULLY",
}

export enum PartOfSpeech {
  NOUN = "NOUN",
  VERB = "VERB",
  ADJECTIVE = "ADJECTIVE",
  ADVERB = "ADVERB",
}

export interface TermTag {
  id: number;
  name: string;
  color: string;
}

export interface TermExample {
  acceptable: boolean;
  exampleText: string;
}

export interface TermMistake {
  mistakeText: string;
  partOfSpeech: PartOfSpeech;
  caseSensitive: boolean;
}

export interface Term {
  id: number;
  hash: string;
  term: string;
  termRule: TermRule;
  partOfSpeech: PartOfSpeech | null;
  description: string;
  caseSensitive: boolean;
  tags: TermTag[];
  examples: TermExample[];
  mistakes: TermMistake[];
  primary: boolean;
  updatedAt: number;
  createdAt: number;
}
