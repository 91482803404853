import { Input } from "@/components/Elements/Input/Input";
import React from "react";
import { NumericRuleDefinition, NumericStyleGuideRule } from "../../../types";

interface NumericRuleFieldProps {
  ruleDefinition: NumericRuleDefinition;
  styleGuideRule: NumericStyleGuideRule;
  onRuleChange: (updatedRule: NumericStyleGuideRule) => void;
}

export const NumericRuleField: React.FC<NumericRuleFieldProps> = ({
  ruleDefinition,
  styleGuideRule,
  onRuleChange,
}) => {
  const handleValueChange = (value: string) => {
    const numericValue = parseInt(value, 10);
    if (!isNaN(numericValue)) {
      onRuleChange({ ...styleGuideRule, numericValue });
    }
  };

  return (
    <Input
      type="number"
      value={styleGuideRule.numericValue}
      onChange={handleValueChange}
      min={ruleDefinition.minValue}
      max={ruleDefinition.maxValue}
      className="w-full"
    />
  );
};
