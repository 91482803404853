import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";

export const deleteHubDocument = async ({ hash }: { hash: string }) => {
  return axios.post("/deleteHubDocument", { hash });
};

type UseDeleteHubDocumentOptions = {
  config?: MutationConfig<typeof deleteHubDocument>;
};

export const useDeleteHubDocument = ({
  config,
}: UseDeleteHubDocumentOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: deleteHubDocument,
  });
};
