import { PartOfSpeech, Term, TermRule, TermTag } from "./types";

export const defaultTermTags: Omit<TermTag, "id">[] = [
  {
    name: "Writing Style",
    color: "#3B82F6",
  },
  {
    name: "Clarity",
    color: "#3B82F6",
  },
  {
    name: "Product",
    color: "#10B981",
  },
  {
    name: "Terminology",
    color: "#6366F1",
  },
  {
    name: "AI Content",
    color: "#8B5CF6",
  },
  {
    name: "Accessibility",
    color: "#F59E0B",
  },
  {
    name: "Grammar",
    color: "#3B82F6",
  },
  {
    name: "SEO",
    color: "#06B6D4",
  },
  {
    name: "Marketing",
    color: "#EC4899",
  },
  {
    name: "Support",
    color: "#84CC16",
  },
  {
    name: "Tone",
    color: "#F97316",
  },
];

export const makeDefaultTerms = (
  tags: TermTag[]
): Omit<Term, "id" | "hash">[] => {
  const termTagsMap = new Map<string, TermTag>();

  for (const tag of tags) {
    termTagsMap.set(tag.name, tag);
  }

  return [
    {
      term: "Utilize",
      termRule: TermRule.DONT_USE,
      partOfSpeech: PartOfSpeech.VERB,
      description:
        'Replace with "use" to simplify and make content more approachable.',
      caseSensitive: false,
      tags: [termTagsMap.get("Writing Style")!, termTagsMap.get("Clarity")!],
      examples: [],
      mistakes: [],
      primary: false,
    },
    {
      term: "Login",
      termRule: TermRule.APPROVED,
      partOfSpeech: PartOfSpeech.NOUN,
      description:
        'Ensure "login" is used as one word in noun form; use "log in" for the verb.',
      caseSensitive: false,
      tags: [termTagsMap.get("Product")!, termTagsMap.get("Terminology")!],
      examples: [],
      mistakes: [
        {
          mistakeText: "log in",
          partOfSpeech: PartOfSpeech.NOUN,
          caseSensitive: false,
        },
        {
          mistakeText: "log-in",
          partOfSpeech: PartOfSpeech.NOUN,
          caseSensitive: false,
        },
      ],
      primary: false,
    },
    {
      term: "Delve",
      termRule: TermRule.DONT_USE,
      partOfSpeech: PartOfSpeech.VERB,
      description:
        'Avoid this generic term often associated with AI-generated text; replace with "explore" or "examine."',
      caseSensitive: false,
      tags: [termTagsMap.get("Writing Style")!, termTagsMap.get("AI Content")!],
      examples: [],
      mistakes: [],
      primary: false,
    },
    {
      term: "Click Here",
      termRule: TermRule.DONT_USE,
      partOfSpeech: PartOfSpeech.VERB,
      description:
        'Use descriptive link text instead, such as "Read the full guide" or "Learn more about SEO."',
      caseSensitive: false,
      tags: [termTagsMap.get("Accessibility")!, termTagsMap.get("SEO")!],
      examples: [],
      mistakes: [],
      primary: false,
    },
    {
      term: "Reach Out",
      termRule: TermRule.APPROVED,
      partOfSpeech: PartOfSpeech.VERB,
      description:
        'Encourages approachable language in customer communications instead of formal phrases like "contact us."',
      caseSensitive: false,
      tags: [termTagsMap.get("Support")!, termTagsMap.get("Tone")!],
      examples: [],
      mistakes: [],
      primary: false,
    },
  ];
};
