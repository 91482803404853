import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useMutation } from "react-query";

export interface ToneAnalysisParams {
  input: string;
}

export interface ToneAnalysisResponse {
  tone_analysis: {
    assertiveness: { justification: string; score: number };
    emotional_content: { justification: string; score: number };
    formality: { justification: string; score: number };
    politeness: { justification: string; score: number };
  };
}

export const generateToneAnalysis = async (
  params: ToneAnalysisParams
): Promise<ToneAnalysisResponse> => {
  return axios.post("/generateToneAnalysis", params);
};

type UseGenerateToneAnalysisOptions = {
  config?: MutationConfig<typeof generateToneAnalysis>;
};

export const useGenerateToneAnalysis = ({
  config,
}: UseGenerateToneAnalysisOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: generateToneAnalysis,
  });
};
