import {
  Button,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Switch,
} from "@/components/Elements";
import React from "react";
import { Control, useFieldArray } from "react-hook-form";

interface TermExampleFieldProps {
  control: Control<any>;
  name: string;
  disabled?: boolean;
}

export const TermExampleField: React.FC<TermExampleFieldProps> = ({
  control,
  name,
  disabled = false,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <FormLabel>Examples</FormLabel>
        <Button
          type="button"
          variant="outlineBlur"
          size="sm"
          onClick={() => append({ exampleText: "", acceptable: true })}
          disabled={disabled}
        >
          Add Example
        </Button>
      </div>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          {index > 0 && <hr className="my-4 border-gray-200" />}
          <div className="space-y-2 mb-4">
            <FormField
              control={control}
              name={`${name}.${index}.exampleText`}
              rules={{
                maxLength: {
                  value: 100,
                  message: "Example text must be 100 characters or less",
                },
              }}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Enter an example"
                      disabled={disabled}
                      maxLength={100}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center justify-between">
              <FormField
                control={control}
                name={`${name}.${index}.acceptable`}
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-2">
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        disabled={disabled}
                      />
                    </FormControl>
                    <FormLabel
                      className={`font-normal ${
                        disabled ? "text-gray-500" : ""
                      }`}
                      style={{ paddingBottom: 6 }}
                    >
                      Acceptable
                    </FormLabel>
                  </FormItem>
                )}
              />
              <Button
                type="button"
                variant="outlineBlur"
                size="sm"
                onClick={() => remove(index)}
                disabled={disabled}
              >
                Remove
              </Button>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
