import { Route, Routes } from "react-router-dom";

import { BrandVoice } from "./BrandVoice";

export const BrandVoiceRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<BrandVoice />} />
    </Routes>
  );
};
