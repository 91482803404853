import {
  Button,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
} from "@/components/Elements";
import React from "react";
import { Control, useFieldArray } from "react-hook-form";
import { PartOfSpeech } from "../../types";

interface TermMistakeFieldProps {
  control: Control<any>;
  name: string;
  disabled?: boolean;
}

export const TermMistakeField: React.FC<TermMistakeFieldProps> = ({
  control,
  name,
  disabled = false,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <FormLabel>Mistakes</FormLabel>
        <Button
          type="button"
          variant="outlineBlur"
          size="sm"
          onClick={() =>
            append({
              mistakeText: "",
              partOfSpeech: PartOfSpeech.NOUN,
              caseSensitive: false,
            })
          }
          disabled={disabled}
        >
          Add Mistake
        </Button>
      </div>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          {index > 0 && <hr className="my-4 border-gray-200" />}
          <div className="space-y-2 mb-4">
            <FormField
              control={control}
              name={`${name}.${index}.mistakeText`}
              rules={{
                maxLength: {
                  value: 100,
                  message: "Mistake text must be 100 characters or less",
                },
              }}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Enter a mistake"
                      disabled={disabled}
                      maxLength={100}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center space-x-2">
              <FormField
                control={control}
                name={`${name}.${index}.partOfSpeech`}
                render={({ field }) => (
                  <FormItem className="flex-grow">
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      disabled={disabled}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Part of Speech" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.values(PartOfSpeech).map((pos) => (
                          <SelectItem key={pos} value={pos}>
                            {pos}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="button"
                variant="outlineBlur"
                size="sm"
                onClick={() => remove(index)}
                disabled={disabled}
              >
                Remove
              </Button>
            </div>
            <FormField
              control={control}
              name={`${name}.${index}.caseSensitive`}
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormLabel
                    className={`font-normal ${disabled ? "text-gray-400" : ""}`}
                  >
                    Case Sensitive
                  </FormLabel>
                </FormItem>
              )}
            />
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
